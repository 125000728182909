import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function Latest() {

    return(
<>
<Container fluid className=''>

<Row className='headingimg1 p-4'>
    <Col className='m-4' lg={12}>
        <h1 className='text-white m-5 px-2 fw-bold text-center'>Latest</h1>
        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Latest</a></div>
    </Col></Row>
    <Row className='p-3'>
        <Col className='p-3' lg={12}>
        <p> The MCA will be conducting an exam to induct Umpires on the State Panel in the months 
of July - August 2024. <br/>

        <a href='https://docs.google.com/forms/d/e/1FAIpQLScR8Ki7E9I3GKDAPKo9-ha6vWHcyyfX3J47eUwPbOeafYklXQ/viewform' target='_blank' className=''> 
        Click here to open: <span class="badge text-bg-danger">New</span>
        </a></p><br/>

        <p>Scorers Examination – 2024
<br/>
        <a href='https://docs.google.com/forms/d/e/1FAIpQLSf1nXMCfJRL5ORayFAiUP1jvgbW_a87h_0GRwqwCk2Sv7BE-A/viewform' target='_blank' className=''> 
        Click here to open: <span class="badge text-bg-danger">New</span>

        </a></p><br/>

  </Col>
    </Row>
</Container>


</>    
    )
    
    }
    
    export default Latest;