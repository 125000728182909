import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MembersPdf from '../../Assets/MCA/life member.pdf';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";

function Members() {

  return (
    <>
      <Container fluid className=''>

        <Row className='headingimg1'>
          <Col className='m-4'>
            <h1 className='text-white m-5 px-2 fw-bold text-center'>Members</h1>
            <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Members</a></div>
          </Col>

        </Row>

        <Row className='p-3 text-center m-3 '>
          <Col lg={6} className='p-3'>
            <div class="card w-75">
              <div class="card-body">
                <h5 class="card-title">Life Member</h5>
                <Link to='../LifeMember' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>

              </div>
            </div>
          </Col>
          <Col lg={6} className='p-3'>
            <div class="card w-75">
              <div class="card-body">
                <h5 class="card-title">Benefactor</h5>
                <Link to='../Benefactor' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>
              </div>
            </div>
          </Col>
          <Col lg={6} className='p-3'>
            <div class="card w-75">
              <div class="card-body">
                <h5 class="card-title">Patron</h5>
                <Link to='../Patron' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>
              </div>
            </div>
          </Col>
          <Col lg={6} className='p-3'>
            <div class="card w-75">
              <div class="card-body">
                <h5 class="card-title">        Intl. Player Female / Male
                </h5>
                <Link to='../IntlPlayer' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>
              </div>
            </div>
          </Col>
          <Col lg={6} className='p-3'>
            <div class="card w-75">
              <div class="card-body">
                <h5 class="card-title">        Founder / Special Gymkhana
                </h5>
                <Link to='../Founder' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>
              </div>
            </div>
          </Col>
          <Col lg={6} className='p-3'>
            <div class="card w-75">
              <div class="card-body">
                <h5 class="card-title">        College
                </h5>
                <Link to='../College' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>
              </div>
            </div>
          </Col>
          <Col lg={6} className='p-3'>
            <div class="card w-75">
              <div class="card-body">
                <h5 class="card-title">                Affiliated Club

                </h5>
                <Link to='../AffiliatedClub' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>
              </div>
            </div>
          </Col>
          <Col lg={6} className='p-3'>

            <div class="card w-75">
              <div class="card-body">
                <h5 class="card-title">                District Cricket Associations

                </h5>
                <Link to='../DistricCricket' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

    </>

  )

}

export default Members;