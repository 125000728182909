import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardImg1 from '../../Assets/logo.jpeg';
import CardImg3 from '../../Assets/t22.png';
import CardImg4 from '../../Assets/t22.png';

import { Link } from "react-router-dom";

function QuoteCards() {

  return (
    <>


      <Container fluid className=' text-dark'>


        <Row className='p-3'>

          <Col lg={4} className='p-4'>
          {/* <Card className='  cardWrapper1 '>
              <iframe  height="315" src="https://www.youtube.com/watch?v=07OpbB3KPho" title="MCA Ground Pune, Maharashtra Cricket Association Stadium, Gahunje" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              
            </Card> */}
            <Card className='  cardWrapper1 '>
            <iframe  height="245" src="https://www.youtube.com/embed/07OpbB3KPho" title="DAY 2 || FINAL || PBKJCA Vs CADENCE  | MCA Senior Invitation League 2024 | MCA Stadium, Gahunje" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      <Card.Body>
                <Card.Title className='text-center text-theme'>DAY 2 || FINAL || PBKJCA Vs CADENCE </Card.Title>
                <Card.Text className='text-grey'>
               
                | MCA Senior Invitation League 2024 | MCA Stadium, Gahunje  
                <p> <i class="fa-regular fa-calendar-days"></i> 18 April 2024  </p>           
                </Card.Text>
                <div className='text-center'>
                  <Link to='https://www.youtube.com/watch?v=07OpbB3KPho' target='_blank' className='px-4 btn btn-warning shadow'>Watch Live</Link>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} className='p-4'>
          <Card className='  cardWrapper1 '>
              <Card.Img variant="top" src={CardImg3} className='cardImg1' alt='img' />
              <Card.Body>
                <Card.Title className='text-center text-theme'>AUSTRALIA TOUR OF INDIA ODISERIES 2022-23 – 2nd ODI</Card.Title>
                <Card.Text className='text-grey'>
               
                The All-India Senior Selection Committee on Monday announced the Indian squad...   
                <p> <i class="fa-regular fa-calendar-days"></i> 31 November 2023  </p>           
                </Card.Text>
                <div className='text-center'>
                  <Link to='/SmartcoachDesktop' className='px-4 btn btn-warning shadow'>Learn More</Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} className='p-4'>
          <Card className='  cardWrapper1 '>
              <Card.Img variant="top" src={CardImg3} className='cardImg1' alt='img' />
              <Card.Body>
                <Card.Title className='text-center text-theme'>AUSTRALIA TOUR OF INDIA ODISERIES 2022-23 – 2nd ODI</Card.Title>
                <Card.Text className='text-grey'>
               
                The All-India Senior Selection Committee on Monday announced the Indian squad...   
                <p> <i class="fa-regular fa-calendar-days"></i> 31 November 2023  </p>           
                </Card.Text>
                <div className='text-center'>
                  <Link to='/SmartcoachDesktop' className='px-4 btn btn-warning shadow'>Learn More</Link>
                </div>
              </Card.Body>
            </Card>
          </Col>

        </Row>




      </Container>
    </>)

}

export default QuoteCards;