
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Physio() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Physio</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Physio</a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                    <h3 className='text-center p-3'>Physio</h3>
                    <p>Maharashtra Cricket Association (MCA) and teams typically employ physiotherapists to provide medical care, injury prevention, and rehabilitation services to players. </p>
                    <p>Physiotherapists play a crucial role in ensuring the physical well-being of cricket players, addressing injuries, implementing strength and conditioning programs, and assisting in the overall management of players' health and fitness.</p>
                    
                </Row>
            </Container>

        </>
    )

}

export default Physio;