import './App.css';
import React from 'react';
import  { useState, useEffect } from "react";

import { BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from './Pages/Layout';
import Home from "./Pages/Home";
import NoPage from "./Pages/NoPage";
import About from '../src/Pages/About';
import HallOfFame from '../src/Pages/HallOfFame';
import MahaTeam from '../src/Pages/MahaTeam';
import Transparency from '../src/Pages/Transparency';
import Mpl from '../src/Pages/Mpl';
import LiveScore from '../src/Pages/LiveScore';
import OnlineRegistration from '../src/Pages/OnlineRegistration';
import Tenders from '../src/Pages/Tenders';
import Members from './Pages/Members';
import Above25lakhs from './Pages/Above25lakhs';
import Elections from './Pages/Elections';
 import FinancialStatement from './Pages/FinancialStatement';
 import Resolutions from './Pages/Resolutions';
 import CampPasses from './Pages/CampPasses';
 import MenifiestOfMatches from './Pages/MenifiestOfMatches';
 import StandInformation from './Pages/StandInformation';
 import Contact from './Pages/Contact';
 import Career from './Pages/Career';
 import Ombudsman from './Pages/Ombudsman';
 import ApexCouncil from './Pages/ApexCouncil';
 import OfficeBearers from './Pages/OfficeBearers';
 import McaStadium from './Pages/McaStadium';
 import Constitution from './Pages/Constitution';
 import AnnualReports from './Pages/AnnualReports';
 import Invitation from './Pages/Invitation';
 import MahaSpeedStar from './Pages/MahaSpeedStar';
 import Selection from './Pages/Selection';
 import Coaches from './Pages/Coaches';
 import Physio from './Pages/Physio';
 import Managers from './Pages/Managers';
 import Videos from './Pages/Videos';
 import Photos from './Pages/Photos';
 import Login from './Pages/Login';
import UploadTenders from './Pages/UploadTenders';
import UploadNews from './Pages/UploadNews';
import ManageTendersNews from './Pages/ManageTendersNews'
import Wmpl from './Pages/Wmpl';
import RefundPolicy from './Pages/RefundPolicy';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Terms from './Pages/Terms';
import AdminCommittee from './Pages/AdminCommittee';
import DistricCricket from './Pages/DistricCricket';
import AffiliatedClub from './Pages/AffiliatedClub';
import College from './Pages/College';
import Founder from './Pages/Founder';
import IntlPlayer from './Pages/IntlPlayer';
import Patron from './Pages/Patron';
import LifeMember from './Pages/LifeMember';
import Benefactor from './Pages/Benefactor';
import MensSelection from './Pages/MensSelection';
import WomensSelection from './Pages/WomensSelection';
import Election2019 from './Pages/Election2019';
import Election2022 from './Pages/Election2022';
import ViewTender from './Pages/view_tenders.php';
import Latest from './Pages/Latest';
import Api from './Pages/Api';
import CricketCommittee from './Pages/CricketCommittee';
function App() {
  const [isLogin, setIsLogin] = useState(false);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NoPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/mahateam" element={<MahaTeam />} />
          <Route path="/tenders" element={<Tenders />} />
          <Route path="/halloffame" element={<HallOfFame />} />
          <Route path="/transparency" element={<Transparency />} />
          <Route path="/mpl" element={<Mpl />} />
          <Route path="/livescore" element={<LiveScore />} />
          <Route path="/onlineregistration" element={<OnlineRegistration />} />
          <Route path="/members" element={<Members />} />
          <Route path="/above25lakhs" element={<Above25lakhs />} />
          <Route path="/elections" element={<Elections />} />
          <Route path="/financialStatement" element={<FinancialStatement />} />
          <Route path="/resolutions" element={<Resolutions />} />
          <Route path="/StandInformation" element={<StandInformation />} />
          <Route path="/menifiestofmatches" element={<MenifiestOfMatches />} />
          <Route path="/camppasses" element={<CampPasses />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ombudsman" element={<Ombudsman />} />
          <Route path="/apexCouncil" element={<ApexCouncil />} />
          <Route path="/career" element={<Career />} />
          <Route path="/officebearers" element={<OfficeBearers />} />
          <Route path="/mcastadium" element={<McaStadium />} />
          <Route path="/constitution" element={<Constitution />} />
          <Route path="/invitation" element={<Invitation />} />
          <Route path="/selection" element={<Selection />} />
          <Route path="/coaches" element={<Coaches />} />
          <Route path="/physio" element={<Physio />} />
          <Route path="/managers" element={<Managers />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/login" element={<Login setLogin={setIsLogin} />} />
          <Route path="/annualreports" element={<AnnualReports />} />
          <Route path="/mahaspeedstar" element={<MahaSpeedStar />} />
          <Route path="/uploadtenders" element={<UploadTenders />} />
          <Route path="/uploadnews" element={<UploadNews />} />
          <Route path="/managetendersnews" element={<ManageTendersNews />} />
          <Route path="/wmpl" element={<Wmpl />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/admincommittee" element={<AdminCommittee />} />
          <Route path="/lifemember" element={<LifeMember />} />
          <Route path="/benefactor" element={<Benefactor />} />
          <Route path="/patron" element={<Patron />} />
          <Route path="/intlplayer" element={<IntlPlayer />} />
          <Route path="/founder" element={<Founder />} />
          <Route path="/college" element={<College />} />
          <Route path="/affiliatedclub" element={<AffiliatedClub />} />
          <Route path="/districcricket" element={<DistricCricket />} />
          <Route path="/mensselection" element={<MensSelection />} />
          <Route path="/womensselection" element={<WomensSelection />} />
          <Route path="/election2022" element={<Election2022 />} />
          <Route path="/election2019" element={<Election2019 />} />
          <Route path="/viewtender" element={<ViewTender />} />
          <Route path="/latest" element={<Latest />} />
          <Route path="/api" element={<Api />} />
          <Route path="/cricketcommittee" element={<CricketCommittee />} />

          
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
