
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WomensOneDay from '../../Assets/MCA/WomensOneDay.pdf';
import WomensT20 from '../../Assets/MCA/WomensT20.pdf';
import Multiday from '../../Assets/MCA/Multiday.pdf';
import MensT20 from '../../Assets/MCA/MensT20.pdf';
import MensLimitedOvers from '../../Assets/MCA/MensLimitedOvers.pdf';

function Invitation() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Invitation</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Invitation</a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                    <Col>
                    <h3 className='text-center p-3'>Invitation</h3>
                    <div className='p-3 text-center'>  <a href={MensT20} class="btn btn-primary" target='_blank'>MCA Playing Conditions for MEN's T-20 Final</a><br/></div>
                    <div className='p-3 text-center'>  <a href={Multiday} class="btn btn-primary" target='_blank'>MCA Playing Conditions for Multiday day final</a><br/></div>
                    <div className='p-3 text-center'> <a href={WomensT20} class="btn btn-primary" target='_blank' >MCA Playing Conditions for WOMEN's T-20 Final</a><br/></div>
                    <div className='p-3 text-center'>   <a href={MensLimitedOvers} class="btn btn-primary" target='_blank'>MCA Playing Conditions for MEN's Limited Overs Final</a><br/></div>
                    <div className='p-3 text-center'>  <a href={WomensOneDay} class="btn btn-primary" target='_blank'>MCA Women's One day Limited Overs Final</a><br/></div>

                   
                  
                  
                 
                  

                   
                    
                   
                   
                    </Col>
                </Row>
            </Container>

        </>
    )

}

export default Invitation;