import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function DistricCricket() {
    return(
<>
<Container fluid className=''>

<Row className='headingimg1'>
  <Col className='m-4'>
    <h1 className='text-white m-5 px-2 fw-bold text-center'>District Cricket Associations</h1>
    <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>District Cricket Associations</a></div>
  </Col>

</Row>

<Row className='p-3 m-3'>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>AURANGABAD DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>JALNA DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>AHMEDNAGAR DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> BEED DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> LATUR DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>HINGOLI DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>PARBHANI DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>NANDED DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> DHULE DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>NASHIK DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>NANDURBAR DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>JALGAON DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> SANGLI DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>SATARA DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>OSMANABAD DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>SOLAPUR DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> KOLHAPUR DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>RATNAGIRI DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> RAIGAD DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> PUNE DISTRICT CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> SINDHUDURG DISTRICT CRICKET ASSOCIATION
        </h6></Col>
      </Row>
      </Container>

</>    
    )
    
    }
    
    export default DistricCricket;