
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function AdminCommittee() {
    return (
        <>
            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Admin Committee</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Admin Committee</a></div>
                    </Col>

                </Row>
                <Row className='p-3 m-3'>
                    <h3 className='text-center p-3'>MAHARASHTRA CRICKET ASSOCIATION
                        COMMITTEES APPOINTED FOR THE TERM 2022-25
                    </h3>
                    <h4 className='text-center p-3'>
                        CAC COMMITTEE

                    </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sacheen Mulay, Chairman
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Ranjeet Khirid, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Abhijeet Deshpande, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Kalpana Paropkari / Tapikar, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Adv. Kamlesh Pisal, Convenor
                    </h6></Col>

                    <h4 className='text-center p-3'>
                        INFRASTRUCTURE COMMITTEE

                    </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sushil Shewale, Chairman
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sacheen Mulay, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sunil Mutha, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Adv. Kamlesh Pisal, Convenor
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Suhas Patwardhan, Member
                    </h6></Col>
                    <h4 className='text-center p-3'>LEGAL COMMITTEE
                    </h4>
                   
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Adv. Ajay Deshmukh, Chairman
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Suhas Patwardhan, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Adv. Kamlesh Pisal, Convenor
                    </h6></Col>

                    <h4 className='text-center p-3'>FINANCE COMMITTEE
                    </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sanjay Bajaj, Chairman
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Atul Jain, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Keshav Vaze, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Adv. Kamlesh Pisal, Convenor
                    </h6></Col>

                    
                   
                   
                    <h4 className='text-center p-3'>
                        TOURNAMENT COMMITTEE</h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Raju Kane, Chairman
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Adv. Kamlesh Pisal, Convenor
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Wasim Sayyed, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Irfan Qureshi, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Rajan Chowk, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Kushal Jain, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Arvind Deshpande, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Abhijeet Chavan, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Kedar Gayawal, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Mahesh Bembade, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sanjay Parida, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sachin Patil, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Riyaz Shaikh, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Suhas Pawade, Member 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Suhas Aaglave, Member 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Pradeep Appaso Khalate, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Dipak More,  Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Ajay Gaikwad, Member
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Nandkumar Redkar, Member 
                    </h6></Col>
                     <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Chandrakant Rembursu, Member   
                    </h6></Col>
                     <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Manish Desai, Member 
                    </h6></Col> 
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Rohit Thorve, Member 
                    </h6></Col> 
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Nandan Thakur, Member 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Mrugank Rathi, Member  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sandeep Jadhav, Member 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Hemant Mirkhilkar, Member 
                    </h6></Col>
               

                    <h4 className='text-center p-3'>
                        UMPIRE COMMITTEE
                    </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Vineet Kulkarni
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Ajay Deshpande
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sandip Gangurde
                    </h6></Col>

                    <h4 className='text-center p-3'>
                    DISCIPLINARY COMMITTEE                    </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Santosh Bobde, Hon. Jt. Secretary 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Raju Kane 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sunil Mutha
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sushil Shewale 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Mrugank Rathi
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Adv. Kamlesh Pisal, Convenor  
Hon. Secretary

                    </h6></Col>
                </Row>


            </Container>


        </>
    )

}

export default AdminCommittee;