
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function CricketCommittee() {
    return (
        <>
            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Cricket Committee </h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Admin Committee</a></div>
                    </Col>

                </Row>
                <Row className='p-3 m-3'>
                    <h3 className='text-center p-3'>MEN’S SENIOR & JUNIOR TEAM SELECTION COMMITTEES &  SUPPORT STAFF FOR BCCI SEASON 2024-25 
                    </h3>
                    <h4 className='text-center p-3'>
                    SENIOR (RANJI TROPHY) - SELECTORS 

                    </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Akshay Darekar, Chairman
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Kiran Adhav, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Rohit Jadhav, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Salil Agharkar, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sangram Atitkar, Selector 
                    </h6></Col>

                    <h4 className='text-center p-3'>
                    SENIOR - SUPPORT STAFF 
                    </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sulakshan Kulkarni, Coach 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Amit Patil, Assistant Coach 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sandip Gaikwad, Physio 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Mahesh Patil, S&C Coach 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Neeraj Thorat, Masseur 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Shyam Iyer, Video Analyst 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Mandar Dedge, Team Manager 
                    </h6></Col>
                    <h4 className='text-center p-3'>UNDER-23 - SELECTORS 
                    </h4>
                   
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Ameya Shrikhande, Chairman
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Atul Gaikwad, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sachin Aradhye, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Rahul Kanade, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Anant Neralkar, Selector 
                    </h6></Col>

                    <h4 className='text-center p-3'>UNDER-23 - SUPPORT STAFF 
                    </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Niranjan Godbole, Coach 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Mohsin Sayyed, Assistant Coach 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Samad Fallah, Bowling Coach 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Pankaj Chopade, Physio 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Vinod Yadav, S&C Coach   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Swwapnil Kadaam, Video Analyst  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Pritesh Kothari, Team Manager  
                    </h6></Col>
                   
                    <h4 className='text-center p-3'>
                    UNDER-19 – SELECTORS </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Milind Gunjal, Chairman
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Shantanu Sugwekar, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Shailesh Bhonsale, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Shirish Kamthe, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Ketan Doshi, Selector 
                    </h6></Col>
                   
                    <h4 className='text-center p-3'>
                    UNDER-19 - SUPPORT STAFF                     </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Ajay Chavan, Coach   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Ashish Suryavanshi, Assistant Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Anupam Sanklecha, Bowling Coach 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Swarup Nimbhore, Physio 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Laksh Gupta, S&C Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Shubham Chavan, Masseur  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Varun Deshpande, Video Analyst   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Rahul Arwade, Team Manager   
                    </h6></Col>

                    <h4 className='text-center p-3'>
                    UNDER-16 – SELECTORS                  </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Rohan Bhosale, Chairman 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Parag More, Selector  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Shaikh Habib, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Chetan Thorat, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Bhalchandra Joglekar, Selector 
                    </h6></Col>
                 
                    <h4 className='text-center p-3'>
                    UNDER-16 - SUPPORT STAFF                  </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Ranjit Pande , Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sachin Nair , Assistant Coach   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Domnic Muthuswamy , Bowling Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Jaideep Narse , Batting Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Suraj Pawar , S&C Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Onkar Aghav , Physio  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Santosh Kambale , Video Analyst 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Mangesh Khedkar  , Team Manager 
                    </h6></Col>
                  
                    <h4 className='text-center p-3'>
                    UNDER-14 - SELECTORS                  </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Aditya Dole , Chairman 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Vinod Wandre , Selector  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Pushkaraj Chavan , Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sumit Chavan , Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Prabhulal Patel , Selector 
                    </h6></Col>

                    <h4 className='text-center p-3'>
                    UNDER-14 - SUPPORT STAFF                  </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Virag Awate   , Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Ashok Gadgil , Assistant Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Subhash Ranjane , Bowling Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Abhishek Godbole , S&C Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Vishal Pramod Deep , Physio  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Vishwajeet Jadhav , Team Manager   
                    </h6></Col>
                   
                    <h4 className='text-center p-3'>
                    WOMEN'S SENIOR & U23 - SELECTORS                  </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Snehal Jadhav, Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Savita Thakar, Selector   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Reshma Dhamankar, Selector   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Sharmila Sali, Selector 
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Gauri Gokhale, Selector   
                    </h6></Col>
                   

                    <h4 className='text-center p-3'>
                    WOMEN'S SENIOR - SUPPORT STAFF                </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Hemant Kinikar  , Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Charudatta Kulkarni , Assistant & Bowling Coach   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Manasi Patwardhan , S&C Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Dipti Dixit , Physio   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sarang Bodhe , Video Analyst   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Pratibha Tore , Team Manager    
                    </h6></Col>


                    <h4 className='text-center p-3'>
                    WOMEN'S UNDER-23 SUPPORT STAFF               </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Shrikant Kalyani , Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Vaishnavi Kale  , Assistant Coach   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Rajesh Mahurkar , Bowling & Fielding Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Yogita Padiyar , S&C Coach    
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Devaki Kale, Physio    
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sagar Deshmukh, Video Analyst     
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Manisha Kolhatkar, Team Manager      
                    </h6></Col>


                    <h4 className='text-center p-3'>
                    WOMEN'S UNDER 19 & UNDER 15 - SELECTORS                </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Neeta Kadam  , Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Jyoti Chaubal    , Selector    
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Kanchan Fajage , Selector   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Sangeeta Khare , Selector   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Manisha Lande , Selector  
                    </h6></Col>
                  
                    <h4 className='text-center p-3'>
                    WOMEN'S UNDER 19 - SUPPORT STAFF               </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Suyash Burkul , Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Shweta Jadhav , Assistant & Bowling Coach   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Anant Tambvekar , S&C Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Priyanka Gaikwad , S&C Coach    
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Kajal Jain , Physio   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Sachin Mane , Video Analyst    
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Pooja Jain , Team Manager    
                    </h6></Col>
                    <h4 className='text-center p-3'>
                    WOMEN'S UNDER 15 - SUPPORT STAFF              </h4>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Mandar Dalvi, Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Kirti Dhanwani, Assistant Coach   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Ganesh Kukade, Fielding Coach  
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mr. Pranav Pawar, S&C Coach    
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Shivani Raut, Physio   
                    </h6></Col>
                    <Col className='border p-3 text-center  bg-theme text-white' lg={6}><h6>Mrs. Parvati Bakale, Team Manager    
                    </h6></Col>
                </Row>


            </Container>


        </>
    )

}

export default CricketCommittee;