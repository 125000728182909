
import React from 'react';
// import Img1 from '../../Assets/slider1.jpg';
import Img2 from '../../Assets/gallery/ground1.jpg';
import Img3 from '../../Assets/gallery/ground1.jpg';
import m1 from '../../Assets/gallery/m1.png';
import m2 from '../../Assets/gallery/m2.png';
import m3 from '../../Assets/gallery/m3.png';
import m4 from '../../Assets/gallery/m4.png';

import Carousel from 'react-bootstrap/Carousel';
import MaskImg from '../../Assets/Mask-group.svg';
import { Container } from 'react-bootstrap';

function Slider() {
  return (
    <>
      <Carousel fade interval={5000} >
        <Carousel.Item >
          <img
            className="d-block w-100"
            src={Img2}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item >
          <img
            className="d-block w-100 "
            src={Img2}
            alt="2 slide"
          />
        </Carousel.Item>
        {/* <Carousel.Item >
          <img
            className="d-block w-100 zoom-img"
            src={m4}
            alt="3 slide"
          />
        </Carousel.Item> */}
      </Carousel>
    </>

  )

}

export default Slider;