import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function RefundPolicy() {
    return (
        <Container fluid className='my-3'>
            <Row>
                
                <Col className='p-5'>
                <h3>RETURNS</h3>
                    <p>Our policy lasts 5 days. If 5 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange.</p>
                  <h3>REFUNDS (IF APPLICABLE)</h3>
                   <p>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.</p>
                 <h3>LATE OR MISSING REFUNDS (IF APPLICABLE)</h3>
                    <p>If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company, it may take some time before your refund is officially posted. Next contact your bank. There is often some processing time before a refund is posted.</p>
                </Col>
            </Row>

        </Container>

    
    );
}


export default RefundPolicy;
