import React from 'react';
import Election1 from '../../Assets/Election/result-office-bearers.pdf';
import Election3 from '../../Assets/Election/Final-Contesting-Candidates-List-and-Summary.pdf';
import Election4 from '../../Assets/Election/order-two-of-e-o.pdf';
import Election5 from '../../Assets/Election/results-mca-elections.pdf';
import Election6 from '../../Assets/Election/clarification-by-e-o.pdf';
import Election8 from '../../Assets/Election/Coriigendum-on-Election-Program.pdf';
import Election9 from '../../Assets/Election/Instruction-for-Voters.pdf';
import Election10 from '../../Assets/Election/Instruction-for-Candidates.pdf';
import Election11 from '../../Assets/Election/Final-Election-Program.pdf';
import Election12 from '../../Assets/Election/DRAFT-VOTER-LIST.pdf';
import Election13 from '../../Assets/Election/sc-judgement-09-08-18.pdf';
import Election14 from '../../Assets/Election/sc-judgement-20-09-19.pdf';
import Election15 from '../../Assets/Election/Minutes-of-CoA.pdf';
import Election16 from '../../Assets/Election/Complaince-Mail.pdf';
import Election17 from '../../Assets/Election/nomination-list-summary.pdf';
import Election18 from '../../Assets/Election/COA-mail-on-Dhiraj-Jadhav.pdf';
import Election19 from '../../Assets/Election/Proforma-A-Authority-Letter.pdf';
import Election20 from '../../Assets/Election/Proforma-B-Resolution-of-District-Association.pdf';
import Election21 from '../../Assets/Election/Proforma-C-Information-about-Post-held.pdf';

import Election22 from '../../Assets/Election/AAMER-SALEEM-DISTRICT-CRICKET-CENTRAL-ZONE-MALE.pdf';
import Election23 from '../../Assets/Election/ABHIJIT-KALE-INTERNATIONAL-PLAYER-MALE.pdf';
import Election24 from '../../Assets/Election/AJAY-GUPTE-BENEFACTORS-PATRONS-&-LIFE-MEMBER-MALE.pdf';
import Election25 from '../../Assets/Election/AMRITA-SHINDE-INTERNATIONAL-PLAYER-FEMALE.pdf';
import Election26 from '../../Assets/Election/ARUN-KAKA-JAGTAP-DISTRICT-CRICKET-CENTRAL-ZONE-MALE.pdf';
import Election27 from '../../Assets/Election/BALJEETSINGH-LANGRI-DISTRICT-CRICKET-EAST-ZONE-MALE.pdf';
import Election28 from '../../Assets/Election/CHANDRAKANT-MATE-DISTRICT-CRICKET-WEST-ZONE-MALE.pdf';
import Election29 from '../../Assets/Election/GAUTAM-SONAWANE-COLLEGE-MEMBER-MALE.pdf';
// import Election30 from '../../Assets/Election/KAKATKAR-VIKAS-MALE.pdf';
import Election31 from '../../Assets/Election/KALPANA-TAPIKAR-INTERNATIONAL-PLAYER-FEMALE.pdf';
import Election32 from '../../Assets/Election/KAMLESH-PISAL-DISTRICT-CRICKET-SOUTH-ZONE-MALE.pdf';
import Election33 from '../../Assets/Election/KIRAN-SAMANT-DISTRICT-CRICKET-WEST-ZONE-MALE.pdf';
import Election34 from '../../Assets/Election/MANGESH-BHUSKUTE-FOUNDER-&-SPECIAL-GYMKHANA-MALE.pdf';
import Election35 from '../../Assets/Election/RAHUL-DHOLEPATIL-FOUNDER-&-SPECIAL-GYMKHANA-MALE.pdf';
import Election36 from '../../Assets/Election/RAJWARDHAN-KADAMBANDE-DISTRICT-CRICKET-NORTH-ZONE-MALE.pdf';
import Election37 from '../../Assets/Election/RIYAZ-BAGBAN-AFFILIATED-CRICKET-CLUB-MALE.pdf';
import Election38 from '../../Assets/Election/SAMBHAJI-CHHATRAPATI-DISTRICT-CRICKET-WEST-ZONE-MALE.pdf';
import Election39 from '../../Assets/Election/SANJAY-BAJAJ-DISTRICT-CRICKET-SOUTH-ZONE-MALE.pdf';
import Election40 from '../../Assets/Election/SANTOSH-BOBADE-DISTRICT-CRICKET-EAST-ZONE-MALE.pdf';
import Election41 from '../../Assets/Election/SHUBHANGI-KULKARNI-INTERNATIONAL-PLAYER-FEMALE.pdf';
import Election42 from '../../Assets/Election/SUNIL-MUTHA-BENEFACTORS-PATRONS-&-LIFE-MEMBER-MALE.pdf';
import Election43 from '../../Assets/Election/VINAYAK-DRAVID-FOUNDER-&-SPECIAL-GYMKHANA-MALE.pdf';
import Election44 from '../../Assets/Election/YAJURVINDRA-SINGH-INTERNATIONAL-PLAYER-MALE.pdf';
import Election45 from '../../Assets/Election/YUVRAJ-PATIL-DISTRICT-CRICKET-NORTH-ZONE-MALE.pdf';
import Election46 from '../../Assets/Election/SHUBHENDRA-BHANDARKAR-AFFILIATED-CLUB-MALE.pdf';

function Election2019() {
    return(
<>
<h2 className='text-center p-3'>MCA election 2019 - 2022</h2>
<div className='py-4 p-3 fs-5'>
<a target="_blank" href={Election1} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>Result of Office Bearer Election of MCA
</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election5} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>
Results of MCA Elections
</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election3} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>
Final Contesting Candidates List and Summary
</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election4} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>
order 2 of EO</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election6} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Clarification by E. O. </a>
</div>

<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election8} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>Corrigendum on Election Program </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election9} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Instruction for Voters </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election10} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Instruction to Candidates</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election11} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Final Election Program </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election12} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> 
Voter List </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election13} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Supreme Court judgements - 09/08/2018 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election14} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Supreme Court judgements - 20/09/2019 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election15} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  CoA minutes </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election16} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Compliance Mail
</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election17} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Nomination List & Summary </a>
</div>
{/* <div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election14} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Nominations</a>
</div> */}
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election18} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Order of Electoral Officer on Dhiraj Jadhav </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election19} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Proforma A </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election20} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Proforma B </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election21} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Proforma C </a>
</div>

<h2 className='text-center p-3'>Nominations</h2>

<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election22} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> AAMER SALEEM - DISTRICT CRICKET - CENTRAL ZONE - MALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election23} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>ABHIJIT KALE - INTERNATIONAL PLAYER - MALE  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election24} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> AJAY GUPTE - BENEFACTORS, PATRONS & LIFE MEMBER -
                  MALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election25} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> AMRITA SHINDE - INTERNATIONAL PLAYER - FEMALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election26} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>ARUN KAKA JAGTAP - DISTRICT CRICKET - CENTRAL ZONE -
                  MALE  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election27} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>BALJEETSINGH LANGRI - DISTRICT CRICKET - EAST ZONE -
MALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election28} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> CHANDRAKANT MATE - DISTRICT CRICKET - WEST ZONE -
MALE</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election29} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> GAUTAM SONAWANE - COLLEGE MEMBER - MALE </a>
</div>
{/* <div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election30} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> KAKATKAR VIKAS - MALE  </a>
</div> */}
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election31} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>   KALPANA TAPIKAR - INTERNATIONAL PLAYER - FEMALE</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election32} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  KAMLESH PISAL - DISTRICT CRICKET - SOUTH ZONE -
MALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election33} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> KIRAN SAMANT - DISTRICT CRICKET - WEST ZONE -
MALE  </a>
</div>

<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election34} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  MANGESH BHUSKUTE - FOUNDER & SPECIAL GYMKHANA -
MALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election35} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> RAHUL DHOLEPATIL - FOUNDER & SPECIAL GYMKHANA -
MALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election36} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  RAJWARDHAN KADAMBANDE - DISTRICT CRICKET - NORTH
ZONE - MALE  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election37} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> RIYAZ BAGBAN - AFFILIATED CRICKET CLUB - MALE  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election38} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  SAMBHAJI CHHATRAPATI - DISTRICT CRICKET - WEST ZONE
- MALE  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election39} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>SANJAY BAJAJ - DISTRICT CRICKET - SOUTH ZONE -
MALE  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election40} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> SANTOSH BOBADE - DISTRICT CRICKET - EAST ZONE -
MALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election41} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> SHUBHANGI KULKARNI - INTERNATIONAL PLAYER -
FEMALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election46} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> SHUBHENDRA BHANDARKAR - AFFILIATED CLUB - MALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election42} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> SUNIL MUTHA - BENEFACTORS, PATRONS & LIFE MEMBER -
MALE </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election43} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> VINAYAK DRAVID - FOUNDER & SPECIAL GYMKHANA - MALE
</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election44} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> YAJURVINDRA SINGH - INTERNATIONAL PLAYER - MALE
                
</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election45} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>YUVRAJ PATIL - DISTRICT CRICKET - NORTH ZONE - MALE
                
</a>
</div>
</>    
    )
    
    }
    
    export default Election2019;