import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Mplimg from '../../Assets/mpl.svg';
import SmallIcon from '../../Assets/small1.png';
import Team1 from '../../Assets/Teams/team1.png';
import Team2 from '../../Assets/Teams/team2.png';
import Team3 from '../../Assets/Teams/team3.png';
import Team4 from '../../Assets/Teams/team4.png';
import Team5 from '../../Assets/Teams/team5.png';
import Sketch from '../../Assets/skt.PNG';
import Laptop from '../../Assets/laptop.png';
function Mpl() {
    return (
        <>
            <Container fluid className=''>

                <Row className='mplbg'>
                    <Col className='m-4'>
                        <div className='text-center'>
                            <img src={Mplimg} alt="img" className='img-fluid m-5 px-2 fw-bold text-center' />
                        </div>
                    </Col>

                </Row>
                <Row className='m-3'>
                    <Col>
                        <div className='text-center'><img src={SmallIcon} alt='img' className='img-fluid' />    <h3 className='text-center p-3'>Teams</h3>
                        </div>
                    </Col>
                </Row>
                <Row className='m-3'>
                    <Col>
                        <div className='text-center'><img src={Team1} alt='img' className='img-fluid' />
                        </div>
                    </Col>
                    <Col>
                        <div className='text-center'><img src={Team2} alt='img' className='img-fluid' />
                        </div>
                    </Col>
                    <Col>
                        <div className='text-center'><img src={Team3} alt='img' className='img-fluid' />
                        </div>
                    </Col>
                    <Col>
                        <div className='text-center'><img src={Team4} alt='img' className='img-fluid' />
                        </div>
                    </Col>
                    <Col>
                        <div className='text-center'><img src={Team5} alt='img' className='img-fluid' />
                        </div>
                    </Col>
                </Row>

                <Row className=''>
                    <img src={Sketch} alt='img2' className='img-fluid' />
                </Row>
                <Row className='p-3'>
                    <Col lg="4">
                        <img src={Laptop} alt='img2' className='img-fluid' /></Col>
                    <Col lg="8" className='d-flex align-items-center justify-content-center'>
                        <div className=' m-3 fs-1 fw-bold'>
                           
                           Get more updates on 
                           <br/>
                            Our Official website MPL
                            <br/>
                        <a  href="https://mplt20.in/" target='_blank' className='btn btn-danger text-center mx-2 fw-bold text-white'>Visit Our Website</a>
                        </div>
                        

                    </Col>
                </Row>
            </Container>


        </>
    )

}

export default Mpl;