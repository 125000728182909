
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Managers() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Managers</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Managers </a></div>
                    </Col>

                </Row>
                <Row className='m-3 text-justify'>
                <h3 className='text-center p-3 '>Managers</h3>
                <p>The managers associated with the Maharashtra Cricket Association may include individuals holding positions such as:</p>
                <p> <span className='fw-bold'>General Manager:</span>  Responsible for overseeing the day-to-day operations of the association, including administration, finance, and human resources.</p>
                <p> <span className='fw-bold'>Facilities Manager:</span>  In charge of managing the cricket facilities owned or operated by the association, including stadiums, training facilities, and other infrastructure.</p>
                <p> <span className='fw-bold'>Event Manager:</span>  Responsible for organizing cricket matches, tournaments, and other events hosted or sanctioned by the association.</p>
                <p> <span className='fw-bold'>Finance Manager:</span>  Oversees the financial affairs of the association, including budgeting, accounting, and financial reporting.</p>
                <p> <span className='fw-bold'>Marketing Manager:</span>  Handles marketing, promotions, and sponsorship activities to generate revenue and enhance the association's brand.</p>
                <p> <span className='fw-bold'> Media Manager:</span> Manages communication and public relations activities, including media relations, press releases, and social media presence.</p>
                <p> <span className='fw-bold'> Development Manager:</span> Focuses on cricket development initiatives, including grassroots programs, talent identification, and coaching development.</p>
              



                
                </Row>
            </Container>

        </>
    )

}

export default Managers;