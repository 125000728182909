
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function Founder() {
    return(
<>
<Container fluid className=''>

<Row className='headingimg1'>
  <Col className='m-4'>
    <h1 className='text-white m-5 px-2 fw-bold text-center'>Founder / Special Gymkhana</h1>
    <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Life Member</a></div>
  </Col>

</Row>
<Row className='p-3 m-3'>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> DECCAN GYMKHANA
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> P Y C HINDU GYMKHANA
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>POONA CLUB LTD
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>  SIR D.M. PETIT PARSEE GYMKHANA
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> THE CLUB OF MAHARASHTRA (Spl. Gymkhana)
        </h6></Col>
      </Row>
      </Container>

</>    
    )
    
    }
    
    export default Founder;