
import React, { useState, useEffect } from 'react';
function Api() {

    const apiUrl = 'https://crickanalyzer.com/Lite/index.php/Api/GetMatchScorecard/364';
fetch(apiUrl)
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(userData => {
    // Process the retrieved user data
    console.log('User Data:',  JSON.stringify(userData));
    document.getElementById("json-data").innerHTML = JSON.stringify(userData);
  })
  .catch(error => {
    console.error('Error:', error);
  });


    return(
<>

      
    
     
<pre id="json-data"></pre>


</>    
    )
    
    }
    
    export default Api;