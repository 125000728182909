import React from 'react';
import Election1 from '../../Assets/Election/SC Order.pdf';
import Election2 from '../../Assets/Election/Appointment_of_EO_dated.pdf';
import Election3 from '../../Assets/Election/Amended_Constitution_of_MCA_in_SGM_dated.pdf';
import Election4 from '../../Assets/Election/Receipt_of_Asst_Charity_Commissioner_Pune.pdf';
import Election5 from '../../Assets/Election/Letter_from_EO_to_MCA_dated.pdf';
import Election6 from '../../Assets/Election/Email_from_Adv_Neela_Gokhale_to_MCA.pdf';
import Election7 from '../../Assets/Election/MCA_letter_to_EO_dated.pdf';
import Election8 from '../../Assets/Election/election_program_of_mca.pdf';
import Election9 from '../../Assets/Election/minutes_of_metting_dated.pdf';
import Election10 from '../../Assets/Election/draft voter list.pdf';
import Election11 from '../../Assets/Election/nomination-form.pdf';
import Election12 from '../../Assets/Election/proforma-a-authority.pdf';
import Election13 from '../../Assets/Election/proforma-b-resolution.pdf';
import Election14 from '../../Assets/Election/proforma-c-info-post-held.pdf';
import Election15 from '../../Assets/Election/final_voter_list.pdf';
import Election16 from '../../Assets/Election/notice_to_madhav_ranade.pdf';
import Election17 from '../../Assets/Election/corrigendum_to_election_program.pdf';
import Election18 from '../../Assets/Election/daily_report_of_nominations_received_by_electoral_officer.pdf';
import Election19 from '../../Assets/Election/list_of_authorised_representatives.pdf';
import Election20 from '../../Assets/Election/nominations_received_by_electoral_officer.pdf';
import Election21 from '../../Assets/Election/daily_report_of_nominations.pdf';
import Election22 from '../../Assets/Election/DAILY_REPORT_OF_NOMINATIONS_RECEIVED_BY_ELECTORAL_OFFICER_30_12_2022.pdf';
import Election23 from '../../Assets/Election/INSTRUCTIONS_FOR_SCRUTINY_DATED_03_01_2023.pdf';
import Election24 from '../../Assets/Election/nominations_received_by_electoral_officer_dated_02_01_2023.pdf';
import Election25 from '../../Assets/Election/candidates_attendance_sheet_during_scrutiny_03_01_2023.pdf';
import Election26 from '../../Assets/Election/polling_counting_instructions.pdf';
import Election27 from '../../Assets/Election/withdrawal_form.pdf';
import Election28 from '../../Assets/Election/List_of_Contesting_Candidates_06_01_2023.pdf';
import Election29 from '../../Assets/Election/Suspension_of_Voting_Rights_of_Shri_Madhav_Ranade_Dt_07_01_2023.pdf';
import Election30 from '../../Assets/Election/MCA_Election_Ballot_Papers_with_Sample.pdf';
import Election31 from '../../Assets/Election/Polling_Counting_duty.pdf';
import Election32 from '../../Assets/Election/Counting_of_Votes_08_01_2023.pdf';
import Election33 from '../../Assets/Election/Category_wise_result_for_the_Apex_Council_08_01_2023.pdf';
import Election34 from '../../Assets/Election/Counting_of_Votes_08_01_2023.pdf';
import Election35 from '../../Assets/Election/Category_wise_result_for_the_Apex_Council_08_01_2023.pdf';
import Election36 from '../../Assets/Election/counting_corrected_copy.pdf';
import Election37 from '../../Assets/Election/Nominations_received_for_Office_Bearer.pdf';
import Election38 from '../../Assets/Election/RESULT_OF_OFFICE_BEARERS.pdf';

function Election2022() {
    return(
<>
<h2 className='text-center p-3'>MCA election 2022 - 2025</h2>
<div className='py-4 p-3 fs-5'>
<a target="_blank" href={Election1} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>Hon Supreme Court order dated 14.09.2022</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election2} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>
Appointment of EO dated 28.09.2022</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election3} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Amended Constitution of MCA in
SGM dated 10.11.2022 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election4} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Receipt of Asst. Charity
Commissioner, Pune dated 28.11.2022 to the Change Report of MCA </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election5} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Email from Adv. Neela Gokhale
to MCA dated 14.12.2022 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election6} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> MCA letter to EO dated 15.12.2022 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election7} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Election Program of MCA 2022-2025 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election8} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Minutes of meeting dated 20.12.2022 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election9} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Draft Voter List of MCA for Elections 2022-2025 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election10} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Nomination Form </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election11} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Proforma A - Authority Letter </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election12} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Proforma B - Resolution by District Cricket Association
</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election13} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Proforma C - Details of post held in past </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election14} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Final Voter List - Date : 26.12.2022</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election15} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Notice to Madhav Ranade - Date 26.12.2022</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election16} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Corrigendum to Election Program
dated 27.12.2022</a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election17} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Nominations Received by
Electoral Officer, dated 27.12.2022 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election18} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> List of Authorised
Representatives, dated 28.12.2022 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election19} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Nominations Received by Electoral Officer, dated 28.12.2022 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election20} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Nominations Received by Electoral Officer, dated 29.12.2022 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election21} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Nominations Received by Electoral Officer, dated 30.12.2022 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election22} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> INSTRUCTIONS FOR SCRUTINY DATED - 02.01.2023.pdf </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election23} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Nominations received by Electoral Officer, dated 02.01.2023 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election24} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Total Nominations received upto 02.01.2023 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election25} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Candidates - Categorywise - 02.01.2023 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election26} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Candidates Attendance Sheet During Scrutiny - 03.01.2023 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election27} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> List of Valid Candidates After Scrutiny - 03.01.2023 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election28} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Polling & Counting Instructions - 04.01.2023 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election29} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Caption Withdrawal Proforma - 04.01.2023 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election30} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> List of Contesting Candidates - 06.01.2023  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election31} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Suspension of Voting Rights of Shri Madhav Ranade Dt. 07.01.2023 </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election32} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Sample Ballot Paper Dt. 07/01/2023  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election33} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Polling Counting duty Dt. 07.01.2023  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election34} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Counting of Votes Dt. 08.01.2023  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election35} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Category wise result for the Apex Council Dt. 08.01.2023  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election36} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Counting of Votes corrected copy - 08.01.2023  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election37} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i> Nominations received for Office Bearer Dt. 08.01.2023  </a>
</div>
<div className='py-4  p-3 fs-5'>
<a target="_blank" href={Election38} className='text-decoration-none text-dark border p-3'>
<i class="fa-solid fa-file-pdf text-danger px-2"></i>  Result Of Office Bearers Dt. 08.01.2023 </a>
</div>

</>    
    )
    
    }
    
    export default Election2022;