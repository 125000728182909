import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Tlogo from '../../Assets/logo33.svg';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Marquee from '../Marquee';
// import Constitution from '../../Pages/Constitution';
import ConstitutionPdf from '../../Assets/MCA/constitution.pdf';
import Mpllogo from '../../Assets/gallery/mplnewlogo.png';
function Header() {

    return (

        <>

            <Container fluid className='p-1'>
                <Navbar expand="lg" variant="primary" className='sticky-top  p-1 bg-white ' >
                    <Container fluid className=' py-0'>
                        <Navbar.Brand href="/" className='fw-bold '>
                            <img
                                src={Tlogo}
                                width="120"
                                height="100"
                                className="d-inline-block align-top p-1 "
                                alt="logo"
                            />

                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <Nav className=''>
                                <Nav.Link as={Link} to="/" className='text-center mx-1 text-primary  font-serif '>Home</Nav.Link>
                                <NavDropdown title="About" id="nav-dropdown" className='text-center mx-1 text-primary  font-serif'>
                                    <NavDropdown.Item as={Link} to="/About" >About MCA</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/Members" >Members</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/ApexCouncil" >Apex Council</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/OfficeBearers" >Office Bearers</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/HallOfFame" >Hall of Fame</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/McaStadium" >MCA Stadium</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="Transparency" id="nav-dropdown" className='text-center mx-1 text-primary   font-serif'>
                                    <a href={ConstitutionPdf} className='text-decoration-none p-3 text-dark' target='_blank'>Constitution</a>

                                    <NavDropdown.Item as={Link} to="./Elections">Elections </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="./AnnualReports">Annual Reports </NavDropdown.Item>
                                    {/* <NavDropdown.Item as={Link} to="./Above25lakhs">Payment above 25 lakhs  </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="./Resolutions">Resolutions Passed by Apex Council (Paid) </NavDropdown.Item> */}
                                    <NavDropdown.Item as={Link} to="./Ombudsman">Ombudsman</NavDropdown.Item>
                                </NavDropdown>

                                <Nav.Link as={Link} to="/Tenders" className='text-center mx-1 text-primary  font-serif'>Tenders</Nav.Link>
                                <Nav.Link as={Link} to="/Latest" className='text-center mx-1  text-primary  font-serif ' >Latest</Nav.Link>
        
                                {/* <NavDropdown title="Stadium" id="nav-dropdown" className='text-center text-primary mx-1 font-serif'>
                                <NavDropdown.Item as={Link} to="./StandInformation">All Stand Information With Seating Capacity</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="./MenifiestOfMatches">Menifiest Of Matches</NavDropdown.Item>
                            </NavDropdown> */}
                                <NavDropdown title="Gallery" id="nav-dropdown" className='text-center text-primary mx-1 font-serif'>
                                    <NavDropdown.Item as={Link} to="/Videos" >Videos</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/Photos" >Photos</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Committees" id="nav-dropdown" className='text-center text-primary text-primary mx-1 font-serif'>
                                    <NavDropdown.Item as={Link} to="/AdminCommittee" >Admin Committee</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/CricketCommittee" >Cricket Committee</NavDropdown.Item>

                                    {/* <NavDropdown title="Cricket Committee" id="nav-dropdown" className='text-center  text-dark mx-1 font-serif' >
                                        <NavDropdown.Item as={Link} to="/Selection" >Selection</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/Coaches" >Coaches</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/Physio" >Physio</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/Managers" >Managers</NavDropdown.Item>

                                    </NavDropdown> */}

                                </NavDropdown>
                                <NavDropdown title="Events" id="nav-dropdown" className='text-center text-primary mx-1 font-serif'>
                                    <NavDropdown.Item as={Link} to="/MahaSpeedStar" >Maha Speed Star</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/Mpl" >MPL</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/Wmpl" >WMPL</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Rules" id="nav-dropdown" className='text-center text-primary mx-1 font-serif'>
                                    <NavDropdown.Item as={Link} to="/Invitation" >Invitation League</NavDropdown.Item>
                                    {/* <NavDropdown.Item as={Link} to="/Mpl" >MPL</NavDropdown.Item> */}
                                </NavDropdown>

                                <Nav.Link as={Link} to="/LiveScore" className='text-center mx-1 font-serif text-primary '>Live Score</Nav.Link>
                                <a href="https://mplt20.in/" className='text-center mx-1 font-serif  shadow ' target='_blank'>
                                    <img
                                        src={Mpllogo}
                                        width="50"
                                        height="50"
                                        className="d-inline-block align-top p-1"
                                        alt="logo"
                                    /></a>
                                <a href="https://www.mahacms.in/" className='btn btn-danger text-center mx-1 font-serif text-white shadow' target='_blank'>Player Registration</a>

                            </Nav>
                        </Navbar.Collapse>

                    </Container>
                </Navbar>
                <Marquee />

            </Container>
        </>
    )

}

export default Header;