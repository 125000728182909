
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SmallIcon from '../../Assets/small1.png';
import VaccancyPdf from '../../Assets/vacancy.pdf';
function Career() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Career</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Career</a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                    <Col>
                        <div className='text-center'><img src={SmallIcon} alt='img' className='img-fluid' />    <h3 className='text-center p-3'>Career</h3>
                        </div>
                        <div className='p-3'>
                            <div className='p-3'>
                                <h4 classname="fw-bold">New Vaccancy</h4>
                                <div class="m-1"><a href={VaccancyPdf} target='_blank' classname="btn btn-success "><button type="button" class="btn btn-primary">Sports Physiotherapists</button>
                                </a></div><br />

                                <div class="m-1">     <a href={VaccancyPdf} target='_blank'  classname="btn btn-success "><button type="button" class="btn btn-primary">Strength & Condition Trainers</button>
                                </a></div>
                            </div>
                            <p>Maharashtra Cricket Association is a dynamic hub where passion meets excellence, and cricketing dreams find their wings. As an organization committed to the growth and development of cricket in the state of Maharashtra, we recognize the pivotal role played by dedicated individuals in shaping the future of the sport. </p>
                            <p>We invite individuals with a deep love for the game and a desire to be part of a vibrant and progressive cricketing community to explore the exciting career avenues at Maharashtra Cricket Association. Your journey with us promises not only professional growth but also the satisfaction of being an integral part of Maharashtra’s cricketing legacy. </p>
                            <p>If you are interested in any of these positions you can apply by mail to cricketmaharashtra@yahoo.com with the position applied for in the subject line. Join us in shaping the future of cricket in Maharashtra! </p>
                        </div>
                    </Col>
                </Row>

            </Container>

        </>
    )

}

export default Career;