import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import small from '../../Assets/small1.png';
import Tender1 from '../../Assets/tenders/tender1.pdf';
import Tender2 from '../../Assets/tenders/tender2.pdf';
import Tender3 from '../../Assets/tenders/tender3.pdf';
import Tender4 from '../../Assets/tenders/tender4.pdf';
import Tender5 from '../../Assets/tenders/tender5.pdf';
import Tender6 from '../../Assets/tenders/tender6.pdf';
import Tender7 from '../../Assets/tenders/tender7.pdf';
import Tender8 from '../../Assets/tenders/tender8.pdf';
import Tender9 from '../../Assets/tenders/tender9.pdf';
import Tender10 from '../../Assets/tenders/tender10.pdf';
import Tender11 from '../../Assets/tenders/tender11.pdf';
import Tender12 from '../../Assets/tenders/tender12.pdf';
import Tender13 from '../../Assets/tenders/tender13.pdf';
import Tender14 from '../../Assets/tenders/tender14.pdf';
import Tender15 from '../../Assets/tenders/tender15.pdf';
import Tender16 from '../../Assets/tenders/tender16.pdf';
import Tender17 from '../../Assets/tenders/tender17.pdf';
import Tender18 from '../../Assets/tenders/tender18.pdf';
import Tender19 from '../../Assets/tenders/tender19.pdf';
import Tender20 from '../../Assets/tenders/tender20.pdf';
import Tender21 from '../../Assets/tenders/tender21.pdf';
import Tender22 from '../../Assets/tenders/tender22.pdf';
import Tender23 from '../../Assets/tenders/tender23.pdf';
import Tender24 from '../../Assets/tenders/tender24.pdf';
import Tender25 from '../../Assets/tenders/tender25.pdf';
import Tender26 from '../../Assets/tenders/tender26.pdf';
import Tender27 from '../../Assets/tenders/tender27.pdf';
import Tender28 from '../../Assets/tenders/tender28.pdf';
import Tender29 from '../../Assets/tenders/ppptender.pdf';
import Tender30 from '../../Assets/tenders/Clothing Tender.pdf';
import Tender31 from '../../Assets/tenders/Stadium Garden AMC.pdf';
function Tenders() {
    return (
        <>
            <Container fluid className=''>

                <Row className='headingimg1 p-4'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-start'>Tenders</h1>
                        <div className='text-white m-5 fw-bold text-start'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Tenders</a></div>
                    </Col></Row>
                <Row>

                    <Col>
                        <div className='text-center pt-3'>
                            <img src={small} alt='img1' className='img-fluid' />
                            <h2 className='text-center text-theme'>Tenders</h2>
                        </div>

                    </Col>

                </Row>
                <Row className='mx-3 mb-3'>
                    
                <Col lg={2} className='border'>
                        <h5 className='text-primary'>Serial No.</h5>

                    </Col>
                    <Col lg={4} className='border'>
                        <h5 className='text-primary'>Tender Name</h5>

                    </Col>
                    <Col lg={2} className='border'>
                        <h5 className='text-primary'>Upload Date</h5>
                    </Col>
                    <Col lg={2} className='border'>
                        <h5 className='text-primary'>Closing Date</h5>
                    </Col>
                    <Col lg={2} className='border'>
                        <h5 className='text-primary'>Download</h5>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>1 </p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>MCA invites tenders for Providing Stadium Garden Landscaping AMC</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>4 July 2024</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>9 July 2024</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender31} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>2 </p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p> MCA Issues tender for Sponsorship/Supply of Clothing for Kits </p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>28th June 2024</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender30} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>3</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>RFP for Appointment of PPP Consultant </p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>20th May 2024</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender29} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>4</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Request for Quotation (RFQ) for Supply & Installation - Portable Cabins (Pavilion Purpose) </p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>14th May 2024</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender28} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>4</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>MCA issues tender for Insurance of MCA International Cricket Stadium, Pune, for details please refer to link below:</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>--</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender1} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>5</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>MCA invites ‘Expression of Interest’ from reputed firms of Chartered Accountants from Pune, for further information, please see the link below:</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>--</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender2} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>6</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>MCA invites tender in sealed envelope for installation of LED lights at MCA International Cricket Stadium, Pune, for details please refer to link below:</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>--</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender3} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>7</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>MCA invites Event Agencies to bid for the Event Management of the upcoming 5 ICC World Cup 2023 Matches at the MCA International Stadium, Gahunje, Pune, for details please refer to link below :</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender4} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>8</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Maharashtra Cricket Association is inviting tender for installation and commissioning of Rainwater Harvesting facilities at the MCA Stadium, Gahunje, Pune, for details please refer to link below</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender5} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>

                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>9</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p> LED Sports Flood light System

                        </p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>

                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender6} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>10</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Outdoor LED Screen
                        </p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender7} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>11</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>LED Floodlighting system - Corrigendum 1</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender8} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>12</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Outdoor LED Screen - Corrigendum 1
                        </p>
                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender9} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>13</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>LED Floodlighting system - Corrigendum 2</p>


                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender10} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>14</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Outdoor LED Screen - Corrigendum 2</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender11} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>15 </p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Turnstile TENDER RECALL- SHORT NOTICE</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender12} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>16 </p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Maharashtra Cricket Association is inviting tender for Hydrostatic Roller for the MCA Stadium. All interested vendors are requested to forward their detailed quotation in sealed envelope at MCA office at MCA International Cricket Stadium, At Post : Gahunje, Teh : Maval, Dist : Pune, Pin Code - 412 101 on or before 14th August 2023 by 5 pm.</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p>14th August 2023 by 5 pm.</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender13} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>17 </p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Maharashtra Cricket Association is looking for Clothing and kit bags sponsors for its various teams for the season 2023-2024. The Requirement for the teams is enclosed herewith. All interested vendors are requested to forward their detailed proposal in sealed envelope at MCA office at MCA International Cricket Stadium, At Post : Gahunje, Teh : Maval, Dist : Pune, Pin Code - 412 101 on or before 5th August 2023 by 5 pm.</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p>5th August 2023 by 5 pm.</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender14} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>18</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Appointing Project Management Consultant and Architect for
                            Expansion of Existing MCA International Cricket, Gahunje,
                            Pune</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender15} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>19 </p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Repairs & Renovations Works - Civil &
                            Waterproofing works at existing MCA International Cricket
                            Stadium at Gahunje, Pun</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender16} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>20 </p>

                    </Col>
                   
                    <Col lg={4} className='border'>
                        <p> Painting of existing MCA International Cricket Stadium,
                            Gahunje, Pune</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender17} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>21</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>painting corrigendum no-1</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender18} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>22 </p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>painting corrigendum no-2</p>


                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender19} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>23 </p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>civil corrigendum 1</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender20} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>24</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>corrigendum short tender notice</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender21} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>25</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>prebid-release</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender22} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>26</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>PROJECT MANAGEMENT CONSULTANT</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender23} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row><Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>27</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Specification of supply Errection testing and commissioning </p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender24} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>28</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Specificstion and Proposed Sturcture of compund wall</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender25} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>29</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Maharashtra Cricket Association is inviting quotations for CCTV cameras for the stadium. Interested companies may send their final quote to the Hon. Secretary by email on "cricketmaharashtra@yahoo.com" before 15th March 2022.</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender26} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
                <Row className='mx-3 mb-3'>
                <Col lg={2} className='border'>
                        <p>30</p>

                    </Col>
                    <Col lg={4} className='border'>
                        <p>Qualification required for tender</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>---</p>
                        </Col>
                    <Col lg={2} className='border'>
                        <p> --</p>

                    </Col>
                    <Col lg={2} className='border'>
                        <p>Click here to download a copy of the tender</p>
                        <a href={Tender27} target='_blank' className='btn btn-danger'>Download</a>
                    </Col>
                </Row>
            </Container>



        </>
    )

}

export default Tenders;