import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import AbhijitKale from '../../Assets/Hall/Abhijit Kale.jpg';
import AmritaShinde from '../../Assets/Hall/Amritaa Pratapsinh Shinde.jpg';
import ChandrakantBorade from '../../Assets/Hall/Chandrakant Borade.jpg';
import DBDeodhar from '../../Assets/Hall/D B Deodhar.jpg';
import DevikaPalshikar from '../../Assets/Hall/Devika Palshikar.jpg';
import HrishikeshKanitkar from '../../Assets/Hall/hrishikesh kanitkar.jpg';
import IqbalSiddhiqui from '../../Assets/Hall/Iqbal siddiqui.jpg'
import KalpanaTapikar from '../../Assets/Hall/Kalpana Paropkari Tapikar.jpg';
import KalyaniDhokarikar from '../../Assets/Hall/Kalyani Dhokarikar.jpg';
import KedarJadhav from '../../Assets/Hall/kedar jadhav.jpg';
import ManjushriPuranik from '../../Assets/Hall/Manjushri Puranik.jpg';
import Neetakadam from '../../Assets/Hall/Neeta kadam Kinare.jpg';
import NilimaJoglekar from '../../Assets/Hall/Nilima Joglekar.jpg';
import PreetiDimri from '../../Assets/Hall/pritidimri.jpg';
import RahulTripathi from '../../Assets/Hall/Rahul Tripathi.jpg';
import RuturajGaikwad from '../../Assets/Hall/ruturaj Gaikwad.png';
import ShubhangiKulkarni from '../../Assets/Hall/Shubhangi Kulkarni.jpg';
import SmritiMandhana from '../../Assets/Hall/Smriti mandhana.jpg';
import SnehalPradhan from '../../Assets/Hall/Snehal Pradhan.jpg';
import SoniyaDabir from '../../Assets/Hall/Soniya Dabir.jpg';
import UjwalaNikam from '../../Assets/Hall/Ujwala Nikam Pawar.jpg';
import YajurvindraSingh from '../../Assets/Hall/Yajurvindra Singh.jpg';
import BabaSidhaye from '../../Assets/Hall/baba sidhaye.jpg';
import VasantRanjane from '../../Assets/Hall/Vasant Ranjane.jpg';
import MunafPatel from '../../Assets/Hall/munaf patel.jpg';
function HallOfFame() {
  
  return (
    <>
      <Container fluid className=''>
        <Row className='headingimg1 p-4'>
          <Col className='m-4'>
            <h1 className='text-white m-5 px-2 fw-bold text-center'>Hall Of Fame</h1>
            <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Hall Of Fame</a></div>
          </Col>


        </Row>
        <Row className='p-3'>
        </Row>
  
        
        <Row className='p-3'>
        <Col className='p-3 ' lg={3}>
            <Card className='text-center bg-primary '>
              <Card.Img variant="top" src={DBDeodhar} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white '>
                <Card.Title>D B Deodhar</Card.Title>
                <Card.Text>1911/12 - 1947/48</Card.Text>

                
              </Card.Body>
            </Card>
          </Col>
    
        <Col className='p-3' lg={3}>
            <Card className=' text-center bg-primary'>
              <Card.Img variant="top" src={ChandrakantBorade} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Chandrakant Borde</Card.Title>
                <Card.Text>1952/53 - 1972/73</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className='p-3' lg={3}>
            <Card className=' text-center bg-primary'>
              <Card.Img variant="top" src={VasantRanjane} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Vasant Ranjane</Card.Title>
                <Card.Text>1958 - 1964</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className='p-3' lg={3}>
            <Card className=' text-center bg-primary'>
              <Card.Img variant="top" src={MunafPatel} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>munaf patel</Card.Title>
                <Card.Text>2006 - 2011</Card.Text>
              </Card.Body>
            </Card>
          </Col>
     
          <Col className='p-3 ' lg={3}>
            <Card className='text-center bg-primary '>
              <Card.Img variant="top" src={IqbalSiddhiqui} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white '>
                <Card.Title>Iqbal Siddhiqui</Card.Title>
                <Card.Text>1992 - 2005</Card.Text>

              </Card.Body>
            </Card>
          </Col> 
         
          <Col className='p-3 ' lg={3}>
            <Card className='bg-primary text-center '>
              <Card.Img variant="top" src={KedarJadhav} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Kedar Jadhav</Card.Title>
                <Card.Text>2014 - 2020</Card.Text>

              </Card.Body>
            </Card>
          </Col>
             
        <Col className='p-3 ' lg={3}>
            <Card className='bg-primary text-center '>
              <Card.Img variant="top" src={RuturajGaikwad} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Ruturaj Gaikwad</Card.Title>
                <Card.Text>2016</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className='p-3 ' lg={3}>
            <Card className=' text-center bg-primary'>
              <Card.Img variant="top" src={RahulTripathi} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Rahul Tripathi</Card.Title>
                <Card.Text>2023</Card.Text>

              </Card.Body>
            </Card>
          </Col>
          <Col className='p-3' lg={3}>
            <Card className='text-center bg-primary'>
              <Card.Img variant="top" src={AbhijitKale} className='img-fluid p-3' />
              <Card.Body className=' text-white '>
                <Card.Title>Abhijit Kale</Card.Title>
                <Card.Text>1993/94 - 2006</Card.Text>

              </Card.Body>
            </Card>
          </Col>
       
         
          <Col className='p-3' lg={3}>
            <Card className='bg-primary text-center '>
              <Card.Img variant="top" src={HrishikeshKanitkar} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title> Hrishikesh Kanitkar </Card.Title>
                <Card.Text>2010</Card.Text>

              </Card.Body>
            </Card>
          </Col>
        
          <Col className='p-3' lg={3}>
            <Card className=' text-center bg-primary'>
              <Card.Img variant="top" src={YajurvindraSingh} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Yajurvindra Singh</Card.Title>
                <Card.Text>1971 - 1982</Card.Text>

              </Card.Body>
            </Card>
          </Col> 
        
          <Col className='p-3' lg={3}>
            <Card className=' text-center bg-primary'>
              <Card.Img variant="top" src={BabaSidhaye} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Baba Sidhaye</Card.Title>
                <Card.Text>1949 - 1966</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        
        
       
       
       
             </Row>

        <Row className='p-3'>
         


        
          <Col className='p-3 ' lg={3}>
            <Card className='text-center bg-primary '>
              <Card.Img variant="top" src={SmritiMandhana} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white '>
                <Card.Title>Smriti Mandhana</Card.Title>
                <Card.Text>2013</Card.Text>

              </Card.Body>
            </Card>
          </Col>
     
          <Col className='p-3 ' lg={3}>
            <Card className='bg-primary text-center'>
              <Card.Img variant="top" src={ShubhangiKulkarni} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Shubhangi Kulkarni
                </Card.Title>
                <Card.Text>1976 - 1991</Card.Text>

              </Card.Body>
            </Card>
          </Col>

          <Col className='p-3 ' lg={3}>
            <Card className='bg-primary text-center ' >
              <Card.Img variant="top" src={AmritaShinde} className='img-fluid p-3' />
              <Card.Body className='text-white'>
                <Card.Title>Amritaa Pratapsinh Shinde
                </Card.Title>
                <Card.Text>2002</Card.Text>

              </Card.Body>
            </Card>
          </Col>
        
          <Col className='p-3' lg={3}>
            <Card className=' text-center bg-primary'>
              <Card.Img variant="top" src={UjwalaNikam} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Ujwala Nikam Pawar</Card.Title>
                <Card.Text>1976 - 1978</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>


        <Row className='p-3'>
          
          

          <Col className='p-3 ' lg={3}>
            <Card className='bg-primary text-center'>
              <Card.Img variant="top" src={DevikaPalshikar} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Devika Palshikar
                </Card.Title>
                <Card.Text>2006 - 2008</Card.Text>

              </Card.Body>
            </Card>
          </Col>
   
          <Col className='p-3 ' lg={3}>
            <Card className='text-center bg-primary '>
              <Card.Img variant="top" src={ManjushriPuranik} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white '>
                <Card.Title>Manjushri Puranik</Card.Title>
                <Card.Text>---</Card.Text>

              </Card.Body>
            </Card>
          </Col>




      
          <Col className='p-3 ' lg={3}>
            <Card className='text-center bg-primary '>
              <Card.Img variant="top" src={KalyaniDhokarikar} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white '>
                <Card.Title>Kalyani Dhokarikar</Card.Title>
                <Card.Text>1995 - 2000</Card.Text>

              </Card.Body>
            </Card>
          </Col>
          <Col className='p-3' lg={3}>
            <Card className=' text-center bg-primary'>
              <Card.Img variant="top" src={SoniyaDabir} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Soniya Dabir</Card.Title>
                <Card.Text>2010 - 2014</Card.Text>

              </Card.Body>
            </Card>
          </Col>
        </Row>


        <Row className='p-3'>
      
          <Col className='p-3 ' lg={3}>
            <Card className='bg-primary text-center'>
              <Card.Img variant="top" src={KalpanaTapikar} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Kalpana Paropkari Tapikar
                </Card.Title>
                <Card.Text>---</Card.Text>

              </Card.Body>
            </Card>
          </Col>
          <Col className='p-3 ' lg={3}>
            <Card className='bg-primary text-center'>
              <Card.Img variant="top" src={Neetakadam} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Neeta kadam Kinare
                </Card.Title>
                <Card.Text>1985</Card.Text>

              </Card.Body>
            </Card>
          </Col>
          <Col className='p-3' lg={3}>
            <Card className='bg-primary text-center '>
              <Card.Img variant="top" src={NilimaJoglekar} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Nilima Joglekar</Card.Title>
                <Card.Text>1978 - 1985</Card.Text>

              </Card.Body>
            </Card>
          </Col>
          <Col className='p-3' lg={3}>
            <Card className=' text-center bg-primary'>
              <Card.Img variant="top" src={SnehalPradhan} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Snehal Pradhan</Card.Title>
                <Card.Text>2006 - 2015</Card.Text>

              </Card.Body>
            </Card>
          </Col>
        </Row>

        
    

        <Row className='p-3'>
         
         
     
          <Col className='p-3  ' lg={3}>
            <Card className='bg-primary text-center'>
              <Card.Img variant="top" src={PreetiDimri} className='img-fluid p-3' />
              <Card.Body className='thirdColor text-white'>
                <Card.Title>Preeti Dimri
                </Card.Title>
                <Card.Text>2006 - 2010</Card.Text>

              </Card.Body>
            </Card>
          </Col>
         
         
        </Row>
      </Container>





    </>

  )

}

export default HallOfFame;