import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
function Contact() {
   return (
      <>
         <Container fluid className=''>

            <Row className='headingimg1 p-4'>
               <Col className='m-4'>
                  <h1 className='text-dark m-5 px-2 fw-bold text-start'>CONTACT US</h1>
                  <div className='text-dark m-5 fw-bold text-start'><a href='/' className=' text-dark m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-dark text-decoration-none m-3'>Contact Us</a></div>
               </Col>


            </Row>
            <Row>
            <Col lg={6} className="p-3 text-center">
                  <div className="mapouter m-3"><div className="gmap_canvas"><iframe className="gmap_iframe" width="100%" height='500' frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=MPF4+QJJ, Mumbai – Pune Hwy, Gahunje, Maharashtra 412101&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://embed-googlemap.com"></a></div><style></style></div>
              
               </Col>
               <Col lg={6} >

                  <Form className='m-3  rounded-4' id="contact-form">


                     <Row className='m-3 p-3 '>
                        <h2 className='m-3'>Get in Touch with Us</h2>

                        <Col lg={12} >
                           <Form.Control type="text" placeholder="Enter Name" className='p-2 m-2' name="from_name" id="from_name" required />
                        </Col>
                        <Col lg={12} >
                           <Form.Control type="email" placeholder="Enter email" className='p-2 m-2' name="user_email" required />
                        </Col>
                        <Col lg={12}>
                           <Form.Control type="text" placeholder="Phone" className='p-2 m-2' name="contact_number" required />
                        </Col>
                        <Col lg={12}>
                           <Form.Control as="textarea" rows={3} className='p-2 m-2' name="message" id="message" required />
                        </Col>
                        <Col lg={12} >

                           <Button variant="success" type="submit" className='p-2 m-2 mb-4 fw-bold'>
                              <Form.Control type="submit" value="Send" />
                           </Button>


                        </Col>

                     </Row>
                  </Form>

               </Col>
             
            </Row>
            <Row className='m-3 p-3'>

               <Col lg={4} >

                  <Row className=' m-1 border p-2 shadow'>
                     <Col sm={2} className='p-2 text-sm-end text-center'><i className="fa-solid fa-envelope fs-1 text-theme"></i> </Col>
                     <Col ><div className='text-sm-start text-center text-grey'>cricketmaharashtra@yahoo.com</div></Col>
                  </Row></Col>
               <Col lg={4}>
                  <Row className='m-1 border p-2 shadow'>
                     <Col sm={2} className='p-2 text-sm-end text-center'><i className="fa-solid fa-location-dot fs-1 text-theme"></i> </Col>
                     <Col ><div className='text-sm-start text-center text-grey'>MPF4+QJJ, Mumbai – Pune Hwy, Gahunje, Maharashtra 412101</div></Col>
                  </Row>
               </Col>
               <Col lg={4}>
                  <Row className='m-1 border p-2 shadow'>
                     <Col sm={2} className='p-2 text-sm-end text-center'><i className="fa-solid fa-phone fs-1 text-theme"></i></Col>
                     <Col ><div className='text-sm-start text-center text-grey'>020-27377162</div></Col>
                  </Row></Col>

            </Row>
            <Row>

            </Row>

         </Container>


      </>

   )

}

export default Contact;