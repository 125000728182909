
import React from 'react';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import QuoteCards from '../../Components/QuoteCards';

function News() {
    return(
        <>

        
        
         <Container fluid className='bg-theme'>

                <Row className='d-flex align-items-center justify-content-center m-3'>
                    <Col className='p-3 text-center' sm={11}>
                        <h2 className='text-white text-center px-3'>NEWS</h2>
                        </Col>
                        <Col className='text-center '>
                        <a className='btn btn-danger shadow' sm={1}>View All</a>
                         </Col>
                         <QuoteCards /> 

                    </Row>
    
                </Container>      
                
                  </>
    )
    
    }
    
    export default News;