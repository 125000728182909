import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
// import CardImg1 from '../../Assets/t22.png';
// import CardImg3 from '../../Assets/t23.png';
// import CardImg4 from '../../Assets/t24.png';

import { Link } from "react-router-dom";

function VideoSlider() {
  return (
    <>

      <Container fluid className=' text-dark'>


        <Row className='p-3'>
          <Col lg={4} className='p-4'>
            <Card className='  cardWrapper1 '>
              {/* <Card.Img variant="top" src={CardImg1} className='cardImg1' alt='img' /> */}
              <iframe  height="315" src="https://www.youtube.com/embed/LmENataNuac" title="MCA Ground Pune, Maharashtra Cricket Association Stadium, Gahunje" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Card>
          </Col>
          <Col lg={4} className='p-4'>
          <Card className='  cardWrapper1 '>
              {/* <Card.Img variant="top" src={CardImg1} className='cardImg1' alt='img' /> */}
              <iframe  height="315" src="https://www.youtube.com/embed/LmENataNuac" title="MCA Ground Pune, Maharashtra Cricket Association Stadium, Gahunje" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              
            </Card>
          </Col>
          <Col lg={4} className='p-4'>
          <Card className='  cardWrapper1 '>
              {/* <Card.Img variant="top" src={CardImg1} className='cardImg1' alt='img' /> */}
              <iframe  height="315" src="https://www.youtube.com/embed/LmENataNuac" title="MCA Ground Pune, Maharashtra Cricket Association Stadium, Gahunje" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              
            </Card>
          </Col>
        </Row>
      </Container>
    </>)

}

export default VideoSlider;