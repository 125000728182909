
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StadiumPdf from '../../Assets/MCA/stadium.pdf';
function McaStadium() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>MCA Stadium</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>MCA Stadium </a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                <Col className='m-3 text-justify'>

                    <h3 className='text-center p-3'>MCA Stadium</h3>
                    <p>
                    The Maharashtra Cricket Association Stadium boasts an impressive outdoor cricket field encircled by elliptical-shaped bleachers supported on sturdy racking beams. These bleachers are organized into two tiers, an upper and lower level, collectively providing seating for up to 45,000 enthusiastic spectators.</p>
                    <p>The elliptical shape of the bleachers not only enhances the aesthetic appeal of the stadium but also ensures excellent sightlines from every vantage point, allowing fans to fully immerse themselves in the excitement of the cricket matches. With ample seating capacity, the stadium provides an exhilarating atmosphere for fans to cheer on their favorite teams and players.</p>
                    <p>The design of the stadium reflects a commitment to providing a world-class sporting experience, combining modern amenities with architectural innovation to create a memorable venue for cricket enthusiasts. Whether it's the roar of the crowd or the breathtaking action on the field, the Maharashtra Cricket Association Stadium offers an unparalleled setting for cricketing events.</p>
                    <p>The stadium’s rainwater drainage system is a unique feature ensuring that the matches can proceed swiftly even in inclement weather. MCA’S decision to adopt a sand-based outfield with and use of specific chemical compound for turf management further augments the quality of playing surface</p>
                    <p>This ensures even during the heaviest downpour due to the three layers base the matches can start within a considerable period of time which keeps the momentum of the match in full flow</p>
                    <p>Overall these features exhibit a comprehensive approach to stadium infrastructure prioritizing both performance and sustainability in facility management</p>
                   
                   <a href={StadiumPdf} target='_blank'>Click here for stadium information</a>
                    </Col>



                </Row>
            </Container>
            <Container fluid className='px-3'>
                <Row className='d-flex align-items-center justify-content-center m-3'>
                    <h3 className='text-theme p-3 text-center'>MATCHES PLAYED AT MCA STADIUM</h3>
                    <Col className=' text-center' lg={2}>
                        <p className='fs-1 text-danger fw-bold count'></p>
                        <p className=' fw-bold'>TEST MATCHES</p>
                    </Col>
                    <Col className=' text-center' lg={2}>
                        <p className='fs-1 text-danger fw-bold count1'></p>
                        <p className=' fw-bold'>ONE DAY INTERNATIONAL</p>
                    </Col>
                    <Col className=' text-center' lg={2}>
                    <p className='fs-1 text-danger fw-bold count2'></p>
                        <p className=' fw-bold'>T/20 INTERNATIONAL </p>
                    </Col>
                    <Col className=' text-center' lg={2}>
                        <p className='fs-1 text-danger fw-bold count3'></p>
                        <p className=' fw-bold'>INDIAN PREMIER LEAGUE (IPL)</p>
                    </Col>
                    <Col className=' text-center' lg={2}>
                        <p className='fs-1 text-danger fw-bold count4'></p>
                        <p className='fw-bold'>TOTAL MATCHES</p>
                    </Col>
                </Row>
                </Container>

        </>
    )

}

export default McaStadium;