
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function StandInformation() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Stand Information</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Stand Information</a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                <h3 className='text-center p-3'>Stand Information</h3>
                <p>The Maharashtra Cricket Association (MCA) Stadium, also known as the Pune Cricket Stadium, features several stands to accommodate spectators. Here's a breakdown of the stands typically found in the stadium:</p>
                <p>North Stand: The North Stand is one of the main stands in the stadium. It offers seating arrangements for spectators to enjoy cricket matches from a vantage point facing the northern side of the ground.</p>
                <p>East Stand: The East Stand provides seating options for spectators along the eastern side of the stadium. It offers views of the cricket action from a perspective facing the eastern direction.</p>
                <p>South Stand: The South Stand is another major stand within the stadium. It provides seating for spectators along the southern side of the ground, allowing them to watch cricket matches with a view facing south.</p>
                <p>West Stand: The West Stand completes the perimeter of the stadium, offering seating arrangements along the western side of the ground. Spectators seated in this stand can enjoy cricket matches with views facing west.</p>
                <p>Pavilion: The Pavilion is a premium seating area typically located near one of the ends of the ground. It often features VIP enclosures, corporate boxes, and hospitality suites, providing an elevated experience for spectators.</p>
                <p>Corporate Boxes: The stadium may also feature corporate boxes situated within various stands or pavilions. These boxes offer a luxurious viewing experience and are often used for hosting corporate events or entertaining guests.</p>
                <p>Media Boxes: Media boxes are designated areas within the stadium for journalists, photographers, and broadcasters to cover cricket matches. They are typically equipped with facilities to facilitate media coverage of the events.</p>
                <p>Seating Capacity: The stadium has a seating capacity of approximately 37,406 spectators. This makes it one of the larger cricket stadiums in India.</p>
                


                
                </Row>
            </Container>

        </>
    )

}

export default StandInformation;