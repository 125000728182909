
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function MensSelection() {
    return(
<>
<Container fluid className=''>
<h3 className='text-center p-3'>MEN’S SENIOR & JUNIOR TEAM SELECTION COMMITTEES &  <br/> 
SUPPORT STAFF FOR BCCI SEASON 2023-24 
</h3>
<Row className='p-3 m-3'>
<h4 className='border p-3 text-center  bg-theme text-white shadow'>SENIOR, U23 & U19 </h4>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Sangram Atitkar 	 </h6>
        <p>Chairman  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Kiran Adhav </h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Shrikant Kate </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Salil Agharkar 	 </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Rohit Jadhav 	 </h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Santosh Jedhe 	 </h6>
        <p>Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Avinash Aware  </h6>
        <p>Assistant Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Samad Fallah 	 </h6>
        <p>Bowling Coach   </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Sandip Gaikwad 	 </h6>
        <p>	Physio </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Mahesh Patil  </h6>
        <p>S&C Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Mandar Dedge 	 </h6>
        <p>Team Manager  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Neeraj Thorat 	 </h6>
        <p>Masseur  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Swapnil Kadam  </h6>
        <p>Video Analyst  </p>
        </Col>

      </Row>


      <Row className='p-3 m-3'>
<h4 className='border p-3 text-center  bg-theme text-white shadow'>UNDER-23 </h4>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Ameya Shrikhande 		 </h6>
        <p>Chairman  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Mahendra More 	</h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Sachin Aradhye  	</h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Samuel Clifton  	 </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Anant Neralkar  	</h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Shrikant Kalyani 	 </h6>
        <p>Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Deepak Shilamkar  	 </h6>
        <p>Assistant Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Domnic Muthuswamy 	 </h6>
        <p>Bowling Coach   </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Swarup Nimbhore 	 </h6>
        <p>	Physio </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Vinod Yadav </h6>
        <p>S&C Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Mangesh Khedkar 	 </h6>
        <p>Team Manager  </p>
        </Col>
       
      </Row>
      <Row className='p-3 m-3'>
<h4 className='border p-3 text-center  bg-theme text-white shadow'>UNDER-19 </h4>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Milind Gunjal </h6>
        <p>Chairman  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Chetan Thorat 	</h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Yuvraj Kadam 	</h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Shailesh Bhonsale  	 </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Shirish Kamthe 		</h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Ajay Chavan  		 </h6>
        <p>Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Anant Tambvekar 		 </h6>
        <p>Assistant Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Anupam Sanklecha 	 </h6>
        <p>Bowling Coach   </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Nikhil Paradkar 		 </h6>
        <p>	Batting Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Onkar Aghav 	</h6>
        <p>Physio </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Laksh Gupta 	 </h6>
        <p>S&C Coach (Trainer)  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Atul Gaikwad   </h6>
        <p>Team Manager  </p>
        </Col>
       
      </Row>

      <Row className='p-3 m-3'>
<h4 className='border p-3 text-center  bg-theme text-white shadow'>UNDER-16 </h4>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Rohan Bhosale </h6>
        <p>Chairman  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Yogesh Pawar 	</h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Kashinath Khadkikar	</h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Bhalchandra Joglekar 	 </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Shaikh Habib </h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Amit Patil  </h6>
        <p>Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Prashant Kamble </h6>
        <p>Assistant Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Vijay Wavare  </h6>
        <p>Bowling Coach   </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Jaideep Narse 	 </h6>
        <p>	Batting Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Tejas Matapurkar </h6>
        <p>Physio </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Swaroop Nimbhore  </h6>
        <p>S&C Coach (Trainer)  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Pritesh Kothari  </h6>
        <p>Team Manager  </p>
        </Col>
       
      </Row>

      <Row className='p-3 m-3'>
<h4 className='border p-3 text-center  bg-theme text-white shadow'>UNDER-14 </h4>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Aditya Dole  </h6>
        <p>Chairman  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Vinod Wandre 	</h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Pushkaraj Chavan </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Sumit Chavan 	 </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Suhas Pawde 	 </h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Virag Awate  </h6>
        <p>Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Vinayak Renuse 	 </h6>
        <p>Assistant Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Prasad Kanade  </h6>
        <p>Bowling Coach   </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Abhishek Godbole  </h6>
        <p>S&C Coach (Trainer)  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Vishal Pramod Deep </h6>
        <p>Physio </p>
        </Col>
       
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Rahul Arwade  </h6>
        <p>Team Manager  </p>
        </Col>
       
      </Row>
</Container>


</>    
    )
    
    }
    
    export default MensSelection;