import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
function marquee() {
  return (
    <>
      <Container fluid className=''>

        <Row>
          <Col>
            <div className="example1 ">
              <span>
              <a href="https://www.mahacms.in/" target='_blank'><h6 className='marquee p-0 text-danger marqueefont '>Please Complete Online Player Registration</h6></a></span>
            </div>
       
          </Col>
        </Row>
      </Container>

    </>

  );
}

export default marquee;