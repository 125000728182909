
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MahaSpeedImg from '../../Assets/mahaspeed.jpg';
import VishwajitThakur from '../../Assets/Vishwajit Thakur.jpg';
import HarshalMishra from '../../Assets/Harshal Mishra.jpg';
import IshaGhule from '../../Assets/Isha Ghule.jpg';
import NikitaSingh from '../../Assets/Nikita Singh.jpg';
function MahaSpeedStar() {
    return (
        <>

            <Container fluid className=''>

                {/* <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Maha Speed Star</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Maha Speed Star</a></div>
                    </Col>

                </Row> */}
                <Row className='m-3'>
                    {/* <h3 className='text-center p-3'>Maha Speed Star</h3> */}
                    <Col lg={12}>                    <img src={MahaSpeedImg} alt='img2' className="img-fluid p-3" />
                    </Col>
                    <Col lg={12}>
                        <h3 className='text-center p-3'>Winners</h3>
                        <Row>
                            <Col lg={3}>
                                <div class="card" >
                                    <img src={VishwajitThakur} class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <h5 class="card-title text-center">Senior Men's</h5>
                                        <p class="card-text text-center"> Vishwajit Thakur (Raigad) <br />  Speed - 142kmph.
                                            <br /> Prize Amount - Rs.50,000/- ( Fifty Thousand Only)</p>
                                    </div>
                                </div></Col>

                            <Col lg={3}>
                                <div class="card" >
                                    <img src={HarshalMishra} class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <h5 class="card-title text-center">Junior Men's</h5>
                                        <p class="card-text text-center"> Harshal Mishra  (Pune)  <br /> Speed -132kmph  <br /> Prize Amount - Rs.25,000/- (Twenty Five Thousand Only)
                                        </p>
                                    </div>
                                </div></Col>

                            <Col lg={3}>
                                <div class="card" >
                                    <img src={IshaGhule} class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <h5 class="card-title text-center">Senior Women's </h5>
                                        <p class="card-text text-center"> 1. Isha Ghule - (Pune) <br />  Speed - 103kmph
                                            <br /> Prize Amount - Rs.50,000/- ( Fifty Thousand Only)</p>
                                    </div>
                                </div></Col>

                            <Col lg={3}>
                                <div class="card" >
                                    <img src={NikitaSingh} class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <h5 class="card-title text-center">Junior Women's</h5>
                                        <p class="card-text text-center"> Nikita Singh (Pune) <br />  Speed - 109kmph.
                                            <br /> Prize Amount -Rs.25,000/- ( Twenty Five Thousand Only)</p>
                                    </div>
                                </div></Col>
                        </Row>
                    </Col>

                </Row>
            </Container>

        </>
    )

}

export default MahaSpeedStar;