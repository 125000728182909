
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sahariya from '../../Assets/saharia.jpg';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";

function Elections() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Elections</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Elections</a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                <h3 className='text-center p-3'>Elections</h3>
                <Col sm={3} className='p-3'>

                <Card varient='light' className='bg-theme'>
                                    <Card.Body className='text-white bg-primary'>
                                    <img src={Sahariya} alt='img' className='img-fluid' />  

                                       
                                        <Card.Text className=''>
                                       
                                        State Election Commissioner, Maharashtra and Electoral Officer, MCA
                                        Email - elections@cricketmaharashtra.com
                                        </Card.Text>
                                      

                                        
                                    </Card.Body>

                                </Card>
                                     </Col>


                    <Col sm={9} className='p-3 '>
                        <p>Message from Electoral Officer to all Members of MCA:</p>
                        <p>I am honoured that, MCA has appointed me as Electoral Officer for conducting the elections to the Apex Council and Office Bearers, in pursuance to the directions given by Hon Supreme Court in Civil Appeal No 4235/2014.  </p>

                        <p> I feel that, all of us will have to take all possible precautions and care during the above election process, since they are required to be conducted in an absolutely free, fair and transparent manner. </p>

                        <p> Let’s strive to make this election a grand success through your wholehearted participation etc.

                            This webpage has been created for greater transparency, faster dissemination of information and your convenience.
                            </p>
                         <div>
                            <Link to='../Election2022' target='_blank' className='p-3 btn btn-primary shadow'> MCA election 2022 - 2025</Link></div><br/>
                            <div><Link to='../Election2019' target='_blank' className='p-3 btn btn-primary shadow'> MCA election 2019 - 2022</Link>
                           </div>


                    </Col>
                </Row>
            </Container>

        </>
    )

}

export default Elections;