
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import WmplImg from '../../Assets/wmpl.png';
import WmplInfo from '../../Assets/wmplinfo.pdf';
import ExpressionInst from '../../Assets/expression.pdf';
function Wmpl() {
    return (
        <>

            <Container fluid className=''>
{/* 
                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Maha Speed Star</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Maha Speed Star</a></div>
                    </Col>

                </Row> */}
                <Row className='m-1'>
                    <Col>
                    {/* <img src={WmplImg} alt='img2' className="img-fluid p-3 rounded"/> */}
                    <h3>WMPL - 2024</h3>
                    <p>After the success of Maharashtra Premier League (MPL) 2023, the Maharashtra Cricket Association (MCA) is delighted to announce the commencing of WMPL (Women’s Maharashtra Premier League). MPL and WMPL are organized under the guidance of the Board of Cricket Control in India (BCCI), and are modelled around the glamorous Indian Premier League (IPL) and Women’s Premier League (WPL).</p>
                    <p>The MCA International Stadium, Gahunje in Pune will be the iconic venue for the MPL and WMPL, scheduled to begin on June 2nd, 2024. The tournament will be broadcast live on JioCinema and Sports18, having widespread coverage and viewership across India.</p>
                    <p>Last season of MPL featured eminent international cricketers from Maharashtra, such as Kedar Jadhav, Ruturaj Gaikwad, and Arshin Kulkarni.</p>
                    <p>WMPL 2024 will feature eminent stars like Smriti Mandhana, Devika Vaidya, Kiran Navghire, Shraddha Pokharkar and Anuja Patil which will bring prominence to the tournament at the national level.</p>
                    <p>The Maharashtra Cricket Association's motive in organizing the MPL and WMPL is to provide a platform for emerging cricketing talents from the state to showcase their skills professionally and gain national recognition.</p>
                    <p>The Maharashtra Cricket Association's motive in organizing the MPL and WMPL is to provide a platform for emerging cricketing talents from the state to showcase their skills professionally and gain national recognition.</p>
                    <a href={WmplInfo} target='_blank'>WMPL Information Document</a>
                    <p>Interested parties must submit their 'Expression of Interest' (EOI) in the given format (attached below) via email to rr@marrquee.com and confirm the same by sending a message on WhatsApp to 9890263111 /7841036111 (Rajesh Rane, Tournament Director) before 5 PM on 24th April 2024.</p>
                    <a href={ExpressionInst} target='_blank'>Expression of Interest</a>

                    </Col>
                </Row>
            </Container>

        </>
    )

}

export default Wmpl;