import React from "react";

import Slide from '../../Assets/logo.jpeg';
// import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import Carousel from 'react-bootstrap/Carousel';

function CardSlider() {
    return (
        <>
     
            <Carousel variant="dark" className="p-3 m-3">
              
                <Carousel.Item className="p-lg-4">
                    <Row>
                    <Col className='p-3' sm={4}>
                        <div class="card text-bg-primary mb-3" style={{ width: '25rem' }}>
                            <div class="card-header"> <a href='#' className="btn btn-danger  text-decoration-none px-4"> Live <i class="fa-solid fa-hand-pointer"></i></a> Womens Under 23 One Day Trophy</div>
                            <div class="card-body">
                                <h5 class="card-title">Primary card title</h5>
                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>
                                <span className='p-3'>VS</span>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>
                            </div>
                        </div>                
                        </Col>   
                        <Col className='p-3' sm={4}>
                        <div class="card text-bg-primary mb-3" style={{ width: '25rem' }}>
                            <div class="card-header"> <a href='#' className="btn btn-danger  text-decoration-none px-4"> Live <i class="fa-solid fa-hand-pointer"></i></a> Womens Under 23 One Day Trophy</div>
                            <div class="card-body">
                                <h5 class="card-title">Primary card title</h5>
                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>
                                <span className='p-3'>VS</span>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>

                            </div>
                        </div>                
                        </Col>   
                        <Col className='p-3' sm={4}>
                        <div class="card text-bg-primary mb-3" style={{ width: '25rem' }}>
                            <div class="card-header"> <a href='#' className="btn btn-danger  text-decoration-none px-4"> Live <i class="fa-solid fa-hand-pointer"></i></a> Womens Under 23 One Day Trophy</div>
                            <div class="card-body">
                                <h5 class="card-title">Primary card title</h5>
                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>
                                <span className='p-3'>VS</span>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>

                            </div>
                        </div>                
                        </Col>   
                        </Row>                  
                </Carousel.Item>
                <Carousel.Item className="p-lg-4">
                    <Row>
                    <Col className='p-3' sm={4}>
                        <div class="card text-bg-primary mb-3" style={{ width: '25rem' }}>
                            <div class="card-header"> <a href='#' className="btn btn-danger  text-decoration-none px-4"> Live <i class="fa-solid fa-hand-pointer"></i></a> Womens Under 23 One Day Trophy</div>
                            <div class="card-body">
                                <h5 class="card-title">Primary card title</h5>
                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>
                                <span className='p-3'>VS</span>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>

                            </div>
                        </div>                
                        </Col>   
                        <Col className='p-3' sm={4}>
                        <div class="card text-bg-primary mb-3" style={{ width: '25rem' }}>
                            <div class="card-header"> <a href='#' className="btn btn-danger  text-decoration-none px-4"> Live <i class="fa-solid fa-hand-pointer"></i></a> Womens Under 23 One Day Trophy</div>
                            <div class="card-body">
                                <h5 class="card-title">Primary card title</h5>
                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>
                                <span className='p-3'>VS</span>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>

                            </div>
                        </div>                
                        </Col>   
                        <Col className='p-3' sm={4}>
                        <div class="card text-bg-primary mb-3" style={{ width: '25rem' }}>
                            <div class="card-header"> <a href='#' className="btn btn-danger  text-decoration-none px-4"> Live <i class="fa-solid fa-hand-pointer"></i></a> Womens Under 23 One Day Trophy</div>
                            <div class="card-body">
                                <h5 class="card-title">Primary card title</h5>
                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>
                                <span className='p-3'>VS</span>
                                <span className='p-3'>
                                <img src={Slide} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"></img>
                                </span>

                            </div>
                        </div>                
                        </Col>   
                        </Row>                  
                </Carousel.Item>
               
              
            </Carousel>
        </>)

}

export default CardSlider;