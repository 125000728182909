
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function IntlPlayer() {
    return(
<>
<Container fluid className=''>

<Row className='headingimg1'>
  <Col className='m-4'>
    <h1 className='text-white m-5 px-2 fw-bold text-center'>Intl. Player Female / Male</h1>
    <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Intl. Player Female / Male</a></div>
  </Col>

</Row>
<Row className='p-3 m-3'>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MS KALYANI DHOKARIKAR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MS REKHA GADRE-GODBOLE
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MS AMRITA SHINDE
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MS MANJUSHRI PURANIK/NADGAUDA

        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MS KALPANA PAROPKARI/ TAPIKAR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MS NEETA KADAM

        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MS SHUBHANGI KULKARNI
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MS NILIMA JOGLEKAR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MS UJWALA H. PAWAR /NIKAM
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MS SONIYA DABIR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MS SNEHAL PRADHAN
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MS PREETI DIMRI
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>  MS DEVIKA PALSHIKAR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR CHANDRAKANT BORDE

        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR YAJURVINDRA SINGH

        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR IQBAL SIDDIQUI

        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR HRISHIKESH KANITKAR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR ABHIJIT KALE
        </h6></Col>
      </Row>
      </Container>

</>    
    )
    
    }
    
    export default IntlPlayer;