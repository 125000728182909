
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IncomePdf from '../../Assets/MCA/income.pdf';
function Above25lakhs() {
    return(
<>
<Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Payments made above Rs 25 lakhs</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Payments made above Rs 25 lakhs</a></div>
                    </Col>

                </Row>
                {/* <Row className='p-3'>
               
                    <Col>
                        <h4 className='p-3'>2023</h4>
                        <Row className='mx-3'>
    <Col lg={3} className='border'>
        <h5 className='text-primary'>NAME OF THE Public Trust</h5>
        <p>Maharashtra Cricket Association</p>

    </Col>
    <Col lg={3} className='border'>
    <h5 className='text-primary'>PAYMENT DATE</h5>
    <p>31/03/2023</p>

    </Col>
 
    <Col lg={3} className='border'>
    <h5 className='text-primary'>AMOUNT IN RS</h5>
        <p>94,93,90,575</p>
    </Col>
    <Col lg={3} className='border'>
    <h5 className='text-primary'>Download Pdf</h5>
        <a href={IncomePdf} target='_blank'>Click here</a>
    </Col>
</Row>
                    </Col>
                </Row> */}
                </Container>



</>    
    )
    
    }
    
    export default Above25lakhs;