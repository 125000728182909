
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
function UploadTenders() {
    
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Upload Tenders</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Upload Tenders</a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                {/* <Col className='m-4'>

                    <h3 className='text-center p-3'>Upload Tenders</h3>
                    <div class="container">
  <form action="submit_tender.php" method="post" enctype="multipart/form-data">
    <label for="tender_name">Tender Name:</label>
    <input type="text" id="tender_name" name="tender_name" required />
    <br/>
    <label for="description">Description:</label>
    <textarea id="description" name="description" required></textarea>
    <br/>
    <label for="closing_date">Closing Date:</label>
    <input type="date" id="closing_date" name="closing_date" required />
    <br/>
    <label for="pdf_upload">Upload PDF:</label>
    <input type="file" id="pdf_upload" name="pdf_upload" required accept=".pdf" />
    <br/>
    <input type="submit" value="Submit" />
  </form>
</div>                    </Col> */}

<Form className='m-3  rounded-4' id="contact-form" action="submit_tender.php" method="post" enctype="multipart/form-data">
<Row className='m-3 p-3 '>
   <h2 className='m-3'>Tender Submission Form</h2>

   <Col lg={12} >
      <Form.Control type="text" placeholder="Enter Tender Name" className='p-2 m-2' id="tender_name" name="tender_name"  required />
   </Col>
   
   <Col lg={12}>
      <Form.Control type="date" placeholder="Closing Date" className='p-2 m-2' id="closing_date" name="closing_date"  required />
   </Col>
   {/* <Col lg={12} >
      <Form.Control  className='p-2 m-2' type="file" id="file" name="file"  accept=".pdf"  required />
   </Col> */}
   <Col lg={12}>
      <Form.Control as="textarea" rows={3} className='p-2 m-2' id="discription" name="discription" required />
   </Col>
   <Col lg={12} >
      <Form.Control  className='p-2 m-2' type="file" id="pdf_upload" name="pdf_upload"  accept=".pdf"  required />
   </Col>
   <Col lg={12} >

      <Button variant="success" type="submit" className='p-2 m-2 mb-4 fw-bold'>
         <Form.Control type="submit" value="Submit"  />
      </Button>


   </Col>

</Row>
</Form>

                </Row>
            </Container>

        </>
    )

}

export default UploadTenders;