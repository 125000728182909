import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function LiveScore() {
    return(
        <>
         <Container fluid className=''>

{/* <Row className='headingimg1'>
    <Col className='m-4'>
        <h1 className='text-white m-5 px-2 fw-bold text-center'>LiveScore</h1>
        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>LiveScore </a></div>
    </Col>

</Row> */}
<Row className='d-flex align-items-center justify-content-center mx-5 mt-2'>
{/* <h3 className='text-center p-3'>Live Score</h3> */}
<iframe src="https://mahacms.in/Android_app/web_scorecard/index.php" height="600" width="250" title="Iframe Example"></iframe>
</Row>
</Container>

        
        
        </>
    
    )
    
    }
    
    export default LiveScore;