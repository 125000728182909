
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Constitution() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Constitution</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Constitution</a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                <h3 className='text-center p-3'>Constitution</h3>
                <p>The constitution of the Maharashtra Cricket Association (MCA) outlines the rules, regulations, and governance structure of the association. Constitutions of cricket associations typically cover various aspects such as membership, powers and duties of office bearers, conduct of meetings, financial matters, disciplinary procedures, and more.</p>
                <p>The constitution is a fundamental document that guides the functioning of the association and ensures transparency, accountability, and adherence to established principles and procedures. It plays a crucial role in governing the affairs of the association and maintaining the integrity of cricket administration within the region.</p>
                </Row>
            </Container>

        </>
    )

}

export default Constitution;