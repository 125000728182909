
import React from 'react';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Layout from '../../Assets/asd.svg';
function About() {
    return (
        <>
            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>About Us</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>About Us</a></div>
                    </Col>

                </Row>
                <Row className='bg-theme text-white p-4 d-flex align-items-center justify-content-center'>
                    <Col lg={6} className='p-3 '>
                        {/* <h2 className='p-3'> DISTRICT ASSCOCIATIONS</h2> */}
                        <h5 className='px-3'> Maharashtra Cricket Association (महाराष्ट्र क्रिकेट संघ) is the governing body of the Cricket activities in Maharashtra. It is affiliated to the Board of Control for Cricket in India.</h5>

                        <h5 className='px-3'> Maharashtra Cricket Association Jurisdiction comprises of following District Associations
                        </h5>

                    </Col>
                    <Col lg={6}>
                        <img src={Layout} alt='img' className='img-fluid p-3' />
                    </Col>
                </Row>

            </ Container >

            <Container fluid className=''>

                <Row className='bg-white text-theme p-3 m-4  d-flex align-items-center justify-content-center'>
                    <Col lg={12}>
                        <h4 className='  fw-bold text-center'>About MCA</h4>
                        <p className='text-dark text-justify'>

                            Cricket Maharashtra is a dedicated platform that embodies the spirit of cricket in the Maharashtra region. As enthusiasts and advocates of the sport, our mission is to foster a vibrant cricketing community, nurturing talent from grassroots to professional levels. Through our comprehensive coverage, we strive to connect cricket enthusiasts, players, and stakeholders across Maharashtra. Our commitment extends beyond the boundary, aiming to instill a love for the game and contribute to the growth of cricket in the region. Join us in celebrating the rich cricketing heritage of Maharashtra and be part of a community that shares the passion for this beloved sport.</p>


                    </Col>
                </Row>

            </ Container >

        </>

    )

}

export default About;