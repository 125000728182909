
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SelectionPdf from '../../Assets/MCA/selection.pdf';
import { Link } from "react-router-dom";

function Selection() {
  return (
    <>

      <Container fluid className=''>

        <Row className='headingimg1'>
          <Col className='m-4'>
            <h1 className='text-white m-5 px-2 fw-bold text-center'>Selection</h1>
            <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Selection</a></div>
          </Col>

        </Row>
        <Row className='m-3'>
          <h3 className='text-center p-3'>MAHARASHTRA CRICKET ASSOCIATION
          </h3>
          {/* <Col lg={6} className='p-3'>
            <div class="card w-75 bg-light shadow">
              <div class="card-body">
                <h5 class="card-title">MEN’S SENIOR & JUNIOR TEAM SELECTION COMMITTEES &  SUPPORT STAFF FOR BCCI SEASON 2024-25 
                </h5>
                <Link to='../MensSelection' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>
              </div>
            </div>
          </Col>
          <Col lg={6} className='p-3'>
            <div class="card w-75  bg-light shadow">
              <div class="card-body">
                <h5 class="card-title">WOMEN’S SENIOR & JUNIOR TEAM SELECTION COMMITTEES & SUPPORT STAFF FOR BCCI SEASON 2024-25
                </h5>
                <Link to='../WomensSelection' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>
              </div>
            </div>
          </Col> */}
          <Col lg={6} className='p-3'>
            <div class="card w-75 bg-light shadow">
              <div class="card-body">
                <h5 class="card-title">MEN’S SENIOR & JUNIOR TEAM SELECTION COMMITTEES &
                  SUPPORT STAFF FOR BCCI SEASON 2023-24
                </h5>
                <Link to='../MensSelection' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>

              </div>
            </div>
          </Col>
          <Col lg={6} className='p-3'>
            <div class="card w-75  bg-light shadow">
              <div class="card-body">
                <h5 class="card-title">WOMEN’S SENIOR & JUNIOR TEAM SELECTION COMMITTEES &
                  SUPPORT STAFF FOR BCCI SEASON 2023-24
                </h5>
                <Link to='../WomensSelection' target='_blank' className='px-4 btn btn-primary shadow'> Click here to view</Link>
              </div>
            </div>
          </Col>
        </Row>


      </Container>

    </>
  )

}

export default Selection;