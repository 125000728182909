
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Cimg from '../../Assets/comitee/mr-rohit-pawar.png';
import Cimg1 from '../../Assets/comitee/mr-kiran-samant.png';
import Cimg2 from '../../Assets/comitee/mr-shubhendra-bhandarkar.png';
import Cimg3 from '../../Assets/comitee/mr-santosh-bobde.png';
import Cimg4 from '../../Assets/comitee/mr-sanjay-bajaj.png';
import Cimg5 from '../../Assets/comitee/mr-suhas-patwardhan.png';
import Cimg6 from '../../Assets/comitee/mr-sunil-mutha.png';
import Cimg7 from '../../Assets/comitee/mr-vinayak-dravid.png';
import Cimg8 from '../../Assets/comitee/mr-veshav-vaze.png';
import Cimg9 from '../../Assets/comitee/mr-sacheen-mulay.png';
import Cimg10 from '../../Assets/comitee/mr-raju-kane.png';
import Cimg11 from '../../Assets/comitee/adv-ajay-deshmukh.png';
import Cimg12 from '../../Assets/comitee/mr-rajwardhan-kadambande.png';
import Cimg13 from '../../Assets/comitee/mr-atul-jain.png';
import Cimg14 from '../../Assets/comitee/adv-kamlesh-pisal.png';
import Cimg15 from '../../Assets/comitee/mr-sushil-shewale.png';
import Cimg16 from '../../Assets/comitee/mr-ranjit-khirid.png';
import Cimg17 from '../../Assets/comitee/mrs-kalpana-paropkari-tapikar.png';
import CimgSGupta from '../../Assets/comitee/Shubhangi gupta.jpg'

function ApexCouncil() {
  return (
    <>

      <Container fluid className=''>

        <Row className='headingimg1'>
          <Col className='m-4'>
            <h1 className='text-white m-5 px-2 fw-bold text-center'>Apex Council</h1>
            <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Apex Council </a></div>
          </Col>

        </Row>
        <Row>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={12}>



            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg} />
              <Card.Body className='thirdColor text-dark '>
                <Card.Title>MR. ROHIT PAWAR</Card.Title>
                <Card.Text>
                 HON. PRESIDENT
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>



        </Row>




        <Row>
          {/* <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>

<Card style={{ width: '18rem' }} className='text-center '>
  <Card.Img variant="top" src={Cimg2} />
  <Card.Body className='thirdColor text-dark '>
    <Card.Title>MR. SHUBHENDRA BHANDARKAR</Card.Title>
    <Card.Text>
      HON. SECRETARY
    </Card.Text>
  </Card.Body>
</Card>


</Col> */}
          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>



            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg1} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. KIRAN SAMANT</Card.Title>
                <Card.Text>
                HON. VICE PRESIDENT
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>

            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg14} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>ADV. KAMLESH PISAL</Card.Title>
                <Card.Text>
                  HON. SECRETARY

                </Card.Text>
              </Card.Body>
            </Card>


          </Col>
          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>



            <Card style={{ width: '18rem' }} className=' text-center'>
              <Card.Img variant="top" src={Cimg3} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. SANTOSH BOBDE
                </Card.Title>
                <Card.Text className='text-uppercase'>
                  HON. JT. SECRETARY
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg4} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. SANJAY BAJAJ</Card.Title>
                <Card.Text>
                  HON. TREASURER               </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        
          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>

            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg6} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. SUNIL MUTHA</Card.Title>
                <Card.Text>
                  PUNE
                </Card.Text>
              </Card.Body>
            </Card>


          </Col>
          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg7} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. VINAYAK DRAVID</Card.Title>
                <Card.Text>
                  PUNE
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg8} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. KESHAV VAZE</Card.Title>
                <Card.Text>
                  PUNE            </Card.Text>
              </Card.Body>
            </Card>
          </Col>

        <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
<Card style={{ width: '18rem' }} className=' text-center '>
  <Card.Img variant="top" src={Cimg9} />
  <Card.Body className='thirdColor text-dark'>
    <Card.Title>MR. SACHEEN MULAY</Card.Title>
    <Card.Text>
      AURANGABAD
    </Card.Text>
  </Card.Body>
</Card>
</Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg10} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. RAJU KANE</Card.Title>
                <Card.Text>
                  JALNA
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg11} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>ADV. AJAY DESHMUKH</Card.Title>
                <Card.Text>
                  HINGOLI
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg12} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. RAJWARDHAN KADAMBANDE</Card.Title>
                <Card.Text>
                  DHULE            </Card.Text>
              </Card.Body>
            </Card>
          </Col>

        <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
<Card style={{ width: '18rem' }} className=' text-center '>
  <Card.Img variant="top" src={Cimg13} />
  <Card.Body className='thirdColor text-dark'>
    <Card.Title>MR. ATUL JAIN</Card.Title>
    <Card.Text>
      JALGAON
    </Card.Text>
  </Card.Body>
</Card>
</Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
            <Card style={{ width: '18rem' }} className='text-center '>
              <Card.Img variant="top" src={Cimg2} />
              <Card.Body className='thirdColor text-dark '>
                <Card.Title>MR. SHUBHENDRA BHANDARKAR</Card.Title>
                <Card.Text>
                  PUNE
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg15} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. SUSHIL SHEWALE</Card.Title>
                <Card.Text>
                  PUNE
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg5} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. SUHAS PATWARDHAN</Card.Title>
                <Card.Text>
                  PUNE
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg16} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. RANJEET KHIRID</Card.Title>
                <Card.Text>
                 MALE ICA REPRESENTATIVE NOMINEE             </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
<Card style={{ width: '18rem' }} className=' text-center '>
  <Card.Img variant="top" src={Cimg17} />
  <Card.Body className='thirdColor text-dark'>
    <Card.Title>MRS. KALPANA PAROPKARI TAPIKAR</Card.Title>
    <Card.Text>
    FEMALE ICA REPRESENTATIVE NOMINEE 
    </Card.Text>
  </Card.Body>
</Card>
</Col>

<Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>
<Card style={{ width: '18rem' }} className=' text-center '>
  <Card.Img variant="top" src={CimgSGupta} />
  <Card.Body className='thirdColor text-dark'>
    <Card.Title>MS. Shubhangi Gupta</Card.Title>
    <Card.Text>CAG Officer</Card.Text>
  </Card.Body>
</Card>
</Col>
      
        </Row>
      </Container>

    </>
  )

}

export default ApexCouncil;