
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function WomensSelection() {
    return(
<>
<Container fluid className=''>

<Row className='p-3 m-3'>
        <h3 className='text-center p-3'>WOMEN’S SENIOR & JUNIOR TEAM SELECTION COMMITTEES & 
SUPPORT STAFF FOR BCCI SEASON 2023-24 

</h3>
<Row className='p-3 m-3'>
<h4 className='border p-3 text-center  bg-theme text-white shadow'>SENIOR, U23 & U19  </h4>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Mrs. Rekha Godbole  </h6>
        <p>Chairman  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Mrs. Manisha Lande </h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Reshma Dhamankar (Marathe) </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Snehal Jadhav </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Kasturi Kolte 	</h6>
        <p>Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Mandar Dalvi   </h6>
        <p>Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Soniya Dabir  </h6>
        <p>Assistant Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Rajesh Mahurkar 	</h6>
        <p>Bowling Coach   </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Manasi Patwardhan </h6>
        <p>	Physio </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Mrs. Pranjali Palkar   </h6>
        <p>S&C Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Bhavana Gavali  </h6>
        <p>Team Manager  </p>
        </Col>
        
      </Row>
      <Row className='p-3 m-3'>
<h4 className='border p-3 text-center  bg-theme text-white shadow'>UNDER-19  </h4>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Suyash Burkul  </h6>
        <p>Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Sonam Tandale </h6>
        <p>Assistant Coach   </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Yogita Padiyar </h6>
        <p> S&C Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Shreya Jamadar  </h6>
        <p>Physio  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Chandarani Kamble 	</h6>
        <p>Team Manager    </p>
        </Col>
        
      </Row>
      <Row className='p-3 m-3'>
<h4 className='border p-3 text-center  bg-theme text-white shadow'>UNDER-23  </h4>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Niranjan Godbole </h6>
        <p>Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Chetan Padiyar </h6>
        <p>Assistant Coach   </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Masuma Tabrazi / Priyanka Gaikwad  </h6>
        <p> S&C Coach  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Shubhangi Joshi  </h6>
        <p>Physio  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Rajshree Adbal </h6>
        <p>Team Manager    </p>
        </Col>
        
      </Row>
      <Row className='p-3 m-3'>
<h4 className='border p-3 text-center  bg-theme text-white shadow'>UNDER-15 </h4>
       
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Ranjit Pande  </h6>
        <p>Coach     </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Vaishnavi Kale  </h6>
        <p>Assistant Coach </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Neeta Kadam  </h6>
        <p>Selector   </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Savita Thakar  </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Kanchan Fajage </h6>
        <p> Selector  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Sangeeta Khare </h6>
        <p>Selector </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Priyanka Gaikwad </h6>
        <p>S&C Coach (Trainer)     </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Dr. Karishma Bogawat  </h6>
        <p>Physio  </p>
        </Col>
        <Col className='border p-3 text-center  bg-light text-dark shadow' lg={4}><h6>Pooja Jain    </h6>
        <p>Team Manager    </p>
        </Col>
      </Row>
      </Row>
</Container>


</>    
    )
    
    }
    
    export default WomensSelection;