
import React from 'react';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
// import QuoteCards from '../../Components/QuoteCards';
import ombudsmanpdf from '../../Assets/ombudsmanpdf.pdf';
function Ombudsman() {
    return (
        <>



            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Ombudsman / Ethical Officer</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Ombudsman / Ethical Officer</a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                    
<Col>
<h5 className='text-dark'>Appointment Letter of Ombudsman / Ethical Officer </h5>
   <a href={ombudsmanpdf} target='_blank'>Click here to Download Pdf</a>
</Col>
                  

                </Row>
            </Container>

        </>
    )

}

export default Ombudsman;