import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';

import v1 from '../../Assets/new video/v1.MP4';
import v2 from '../../Assets/new video/v2.MP4';
import v3 from '../../Assets/new video/v3.MP4';
import v4 from '../../Assets/new video/v4.MP4';
import v5 from '../../Assets/new video/v5.MP4';
import v6 from '../../Assets/new video/v6.MP4';
import v7 from '../../Assets/new video/v7.MP4';
import v8 from '../../Assets/new video/v8.MP4';
import v9 from '../../Assets/new video/v9.MP4';
import v10 from '../../Assets/new video/v10.MP4';
import v11 from '../../Assets/new video/v11.MP4';
import v12 from '../../Assets/new video/v12.mp4';
import v13 from '../../Assets/new video/v13.MP4';
import v14 from '../../Assets/new video/v14.mp4';
import v15 from '../../Assets/new video/v15.MP4';
import v16 from '../../Assets/new video/v16.MP4';
import v17 from '../../Assets/new video/v17.mp4';
import v18 from '../../Assets/new video/v18.MP4';
import v19 from '../../Assets/new video/v19.mp4';
import v20 from '../../Assets/new video/v20.MP4';
import v21 from '../../Assets/new video/v21.mp4';
import v22 from '../../Assets/new video/v22.mp4';
import v23 from '../../Assets/new video/v23.mp4';
import v24 from '../../Assets/new video/v24.mp4';

function Videos() {
    var vid = [{ src: v1 }, { src: v2 }, { src: v3 }, { src: v4 }, { src: v5 }, { src: v6 }, { src: v7 }, { src: v8 }, { src: v9 }, { src: v10 }, { src: v11 }, { src: v12 }, { src: v13 }, { src: v14 }, { src: v15 }, { src: v16 }, { src: v17 }, { src: v18 }, { src: v19 }, { src: v20 }, { src: v21 }, { src: v22 }, { src: v23 }, { src: v24 }]

    return (
        <>
            <Container fluid>
                <Row className='headingimg1'>
                    <Col className='m-5'>
                        <h1 className='text-white m-4 mx-4 px-2 fw-bold text-center'>Videos</h1>
                        <div className='text-white m-4 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i class="fa-solid fa-circle-arrow-right fa-beat"></i><a className='text-white text-decoration-none m-3'>
                            Videos</a></div>
                    </Col>
                </Row>
                <Row >

                    {vid.map((video, index) => {
                        return (<Col key={index} >

                            <div className='text-center' >

                                <iframe
                                    src={video.src}
                                    height="300"
                                    id="player"
                                    title="Vimeo video"
                                    allowFullScreen
                                    className='rounded-4 p-1'
                                ></iframe>
               </div>
                        </Col>)
                        
                    })}
<script >  var player =  document.getElementById('player');
player.mute()</script>
                </Row>

            </Container>


        </>
    )

}

export default Videos;