
import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import g1 from '../../Assets/gallery/g1.jpg';
import g2 from '../../Assets/gallery/g2.jpg';
import g3 from '../../Assets/gallery/g3.jpg';
import g4 from '../../Assets/gallery/g4.jpg';
import g5 from '../../Assets/gallery/g5.jpg';
import g6 from '../../Assets/gallery/g6.jpg';
import g7 from '../../Assets/gallery/g7.jpg';
import g8 from '../../Assets/gallery/g8.jpg';
import g9 from '../../Assets/gallery/g9.jpg';
import g10 from '../../Assets/gallery/g10.jpg';
import g11 from '../../Assets/gallery/g11.jpg';
import g12 from '../../Assets/gallery/g12.jpg';
import n1 from '../../Assets/gallery/n1.JPG';
import n2 from '../../Assets/gallery/n2.JPG';

import n5 from '../../Assets/gallery/n5.JPG';
import n6 from '../../Assets/gallery/n6.JPG';
import n7 from '../../Assets/gallery/n7.JPG';
import n8 from '../../Assets/gallery/n8.JPG';
import n9 from '../../Assets/gallery/n9.JPG';
import n10 from '../../Assets/gallery/n10.JPG';
import n11 from '../../Assets/gallery/n11.JPG';
import n12 from '../../Assets/gallery/n12.JPG';
import n13 from '../../Assets/gallery/n13.JPG';
import n14 from '../../Assets/gallery/n14.JPG';
import n15 from '../../Assets/gallery/n15.JPG';
import n16 from '../../Assets/gallery/n16.JPG';
import n17 from '../../Assets/gallery/n17.JPG';
import n18 from '../../Assets/gallery/n18.JPG';
import n19 from '../../Assets/gallery/n19.JPG';
import n20 from '../../Assets/gallery/n20.JPG';
import n21 from '../../Assets/gallery/n21.JPG';
import n22 from '../../Assets/gallery/n22.JPG';
import n23 from '../../Assets/gallery/n23.JPG';

import Card from 'react-bootstrap/Card';

function Photos() {
  

    var img = [ { imgSrc: n1 }, { imgSrc: n2 }, { imgSrc: n5 }, { imgSrc: n6 }, { imgSrc: n7 }, { imgSrc: n8 }, { imgSrc: n9 }, { imgSrc: n10 }, { imgSrc: n11 }, { imgSrc: n12 }, { imgSrc: n13 }, { imgSrc: n14 }, { imgSrc: n15 }, { imgSrc: n16 }, { imgSrc: n17 }, { imgSrc: n18 }, { imgSrc: n19 }, { imgSrc: n20 }, { imgSrc: n21 }, { imgSrc: n22 }, { imgSrc: n23 }, { imgSrc: g1 }, { imgSrc: g2 }, { imgSrc: g3 }, { imgSrc: g4 }, { imgSrc: g5 }, { imgSrc: g6 }, { imgSrc: g7 }, { imgSrc: g8 }, { imgSrc: g9 }, { imgSrc: g10 }, { imgSrc: g12 }, { imgSrc: g11 } ]
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Photos</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Photos </a></div>
                    </Col>

                </Row>
                
                <Row className='p-3'>
                <h3 className='text-center p-3'>Photos</h3>
                <a href="https://www.instagram.com/maharashtracricketofficial/" target="_blank">For more photos visit the link<i className="fa-brands fa-instagram fs-4 mx-2 text-dark rotate"></i></a>

                    {img.map((images, index) => {
                        return (<Col key={index} sm={6} lg={4} className='p-3'>
                            <Card>
                                <Card.Img variant="top" src={images.imgSrc} className='img-fluid rounded ' />

                            </Card>
                        </Col>)
                    })}
                                   

                </Row>

                
            </Container>

        </>
    )

}

export default Photos;