
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Cimg from '../../Assets/comitee/mr-rohit-pawar.png';
import Cimg1 from '../../Assets/comitee/mr-kiran-samant.png';
import Cimg3 from '../../Assets/comitee/mr-santosh-bobde.png';
import Cimg4 from '../../Assets/comitee/mr-sanjay-bajaj.png';
import Cimg14 from '../../Assets/comitee/adv-kamlesh-pisal.png';
function OfficeBearers() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Office Bearers</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Office Bearers </a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                <h3 className='text-center p-3'>Office Bearers</h3>
                <p> The office bearers of cricket associations like MCA include positions such as President, Vice President, Secretary, Treasurer, and other executive committee members. These individuals are responsible for overseeing the administration, governance, and management of cricket-related activities within the association.</p>
                


                
                </Row>
                <Row>

<Col className='p-3  d-flex justify-content-center justify-content-center' lg={12}>



  <Card style={{ width: '18rem' }} className=' text-center '>
    <Card.Img variant="top" src={Cimg} />
    <Card.Body className='thirdColor text-dark '>
      <Card.Title>MR. ROHIT PAWAR</Card.Title>
      <Card.Text>
      HON. PRESIDENT

      </Card.Text>
    </Card.Body>
  </Card>
</Col>


</Row>
<Row>
<Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>



<Card style={{ width: '18rem' }} className=' text-center '>
  <Card.Img variant="top" src={Cimg1} />
  <Card.Body className='thirdColor text-dark'>
    <Card.Title>MR. KIRAN SAMANT</Card.Title>
    <Card.Text>
    HON. VICE PRESIDENT
    </Card.Text>
  </Card.Body>
</Card>
</Col>


<Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>

<Card style={{ width: '18rem' }} className=' text-center '>
  <Card.Img variant="top" src={Cimg14} />
  <Card.Body className='thirdColor text-dark'>
    <Card.Title>ADV. KAMLESH PISAL</Card.Title>
    <Card.Text>
    HON. SECRETARY

    </Card.Text>
  </Card.Body>
</Card>


</Col>
<Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>



<Card style={{ width: '18rem' }} className=' text-center'>
  <Card.Img variant="top" src={Cimg3} />
  <Card.Body className='thirdColor text-dark'>
    <Card.Title>MR. SANTOSH BOBDE
    </Card.Title>
    <Card.Text className='text-uppercase'>
      Hon. Jt. Secretary

    </Card.Text>
  </Card.Body>
</Card>
</Col>

          <Col className='p-3  d-flex justify-content-center justify-content-center' lg={3}>



            <Card style={{ width: '18rem' }} className=' text-center '>
              <Card.Img variant="top" src={Cimg4} />
              <Card.Body className='thirdColor text-dark'>
                <Card.Title>MR. SANJAY BAJAJ</Card.Title>
                <Card.Text>
                  HON. TREASURER               </Card.Text>
              </Card.Body>
            </Card>
          </Col>

</Row>

            </Container>

        </>
    )

}

export default OfficeBearers;