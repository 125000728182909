
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function CampPasses() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Complementary Passes</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Complementary Passes </a></div>
                    </Col>

                </Row>
                <Row className='m-3'>
                <h3 className='text-center p-3'>Complementary Passes</h3>
                


                
                </Row>
            </Container>

        </>
    )

}

export default CampPasses;