import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function AffilitedClub() {
    return(
<>

<Container fluid className=''>

<Row className='headingimg1'>
  <Col className='m-4'>
    <h1 className='text-white m-5 px-2 fw-bold text-center'>Affiliated Club</h1>
    <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Affiliated Club</a></div>
  </Col>

</Row>
<Row className='p-3 m-3'>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> CENTRAL BANK OF INDIA
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>C.D.A. (OFFICERS), PUNE
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>
          THE PREMIERE AUTOMOBILE LTD (M&T DIV.) EMPLOYEES SOCIAL
          SPORTS CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> DEFENCE ACCOUNTS RECREATION CLUB SOUTHERN COMMAND
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> THE HON. SECRETARY KIRLOSKAR OIL ENGINEES LTD
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> KIRLOSKAR PNEUMATICS SPORTS CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> THE HON. SECRETARY METRO CRICKET CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> NEW INDIA ASSUARANCE CO. SPORTS CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> SANDVIK EMPLOYEES CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> S K F LTD
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>SPORTSMEN CRICKET CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>J N MARSHAL (SPIREX) SPORTS CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> TELCO SPORTS CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>UNITED SPORTS CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> VILAS CRICKET CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>Y M C A SPORTS CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> YOUNGSTARS CRICKET CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> BANK OF INDIA CRICKET CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> INOX AIR PRODUCTS LTD
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> JUDICIAL CRICKET CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> ZOROASTRIAN YOUTH CRICKET ASSOCIATION
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>TAYYABI CRICKET CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>STARS CRICKET CLUB
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> VIJAY CRICKET CLUB

        </h6></Col>

      </Row>
      </Container>


</>    
    )
    
    }
    
    export default AffilitedClub;