
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function Benefactor() {
    return(
<>
<Container fluid className=''>

<Row className='headingimg1'>
  <Col className='m-4'>
    <h1 className='text-white m-5 px-2 fw-bold text-center'>Benefactor</h1>
    <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Benefactor</a></div>
  </Col>

</Row>
<Row className='p-3 m-3'>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR ACHUYUT DEODHAR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR HEMANT GONDHALEKAR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MRS RAJNITAI GORE
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR RAMCHANDRA B. JOSHI
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>  MR AVINASH JOSHI
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR RAJSHEKHAR KARMARKAR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>  MR VIJAYKUMAR V MENKAR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR SRINIWAS KELKAR
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR MADHUSUDAN V SHAH
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR SAM F. IRANI
        </h6></Col>
        <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR MADHAV Y. RANADE
        </h6></Col>
      </Row>



</Container>



</>    
    )
    
    }
    
    export default Benefactor;