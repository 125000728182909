
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function LifeMember() {
    return(
<>
<Container fluid className=''>

<Row className='headingimg1'>
  <Col className='m-4'>
    <h1 className='text-white m-5 px-2 fw-bold text-center'>Life Member</h1>
    <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Life Member</a></div>
  </Col>

</Row>
<Row className='p-3 m-3'>
          {/* <h3 className='text-center p-3'>Life Member</h3> */}
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR PRABHAKAR SADASHIV GOKHALE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR ARUN VISHWANATH KESKAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR AJAY B. SHIRKE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR DHANPAL SHAH
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SHRIKANT KELKAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR SUDHAKAR SHANBHAG
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR PRAMOD KSHIRE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR DILIP BACHUWAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR KAMLESH THAKKAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SURENDRA BHAVE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR RIYAZ BAGWAN
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SUBHASH LODHA
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>PROF. P K PATEL
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR CHANDRAKANT MATE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR AAMER SALEEM
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR DNYANESHWAR KAKADE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR JAYANT YELULKAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR PANDURANG SALGAONKAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR KIRAN V JOSHI
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR PRASHANT RAI
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR RATAN KUITE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR BHAGWAN KAKAD
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>ADV ABHAY APTE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>ADV MANOJ WADEKAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> ADV U D KHARE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SUNIL S. MUTHA
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR SHUBHENDRA BHANDARKAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SURESH SADHALE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SURESH MAHAJANI
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SHEKHAR GHOSH
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR KAISER FAKIH
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR RAJU KANE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR R A PATANKAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR VIJAY BHOSALE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> DR. VIKRAM PHATAK
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>DR ABHISHEK JOSHI
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> PROF. BHARAT CHAMBALE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR DATTA BANDGAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SHAMKANT DESHMUKH
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR VASUDEV (SHYAM) OKA
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR ATUL JAIN
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR UMESH J. TRIVEDI
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR RAJESH PATWARDHAN
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR HARSHWARDHAN APTE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR KIRAN SAMANT
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR AJAY GUPTE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR VIKAS KAKATKAR
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR VIJAYKUMAR TAMHANE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR SAMEER RAKATE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR HARIHARAN IYER
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR S G HIREMATH
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR DHANANJAY PHALKE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>ADV KAMLESH PISAL
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SANKET R. SHANBHAG
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR ASHUTOSH AGASHE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR DINESH KUNDANMAL MEHER
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SACHEEN MULAY
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR SUHAS PATWARDHAN
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR R R DESHPANDE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MS PRIYANKA THORVE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR RAHUL DHOLE PATIL
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR ASHUTOSH M. GOKHALE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR AVDHUT N PANDIT
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR BANKIM JAYANTILAL SHAH
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>  MR RAJWARDHAN KADAMBANDE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>  MR SANJAY BAJAJ
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR SANTOSH BOBADE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR SAMBHAJI CHHATRAPATI
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>  MR YUVRAJ PATIL
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>MR GAUTAM SONAWANE
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6>  MR RAHUL PRADEEP DESHMUKH
          </h6></Col>
          <Col className='border p-3 text-center  bg-theme text-white' lg={4}><h6> MR RAVI GANESH SHINDE
          </h6></Col>
        </Row>

        </Container>
</>    
    )
    
    }
    
    export default LifeMember;