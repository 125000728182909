
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Coaches() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Coaches</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Coaches</a></div>
                    </Col>

                </Row>
                <Row className='m-3 text-justify'>
                    <h3 className='text-center p-3'>Coaches</h3>
                    <p className='text-justify'>MCA typically appoint coaches at various levels to oversee the development and training of players. These coaches may include head coaches, assistant coaches, specialized coaches (such as batting, bowling, and fielding coaches), and age-group coaches.</p>
                    <p className='text-justify'>The coaches associated with the Maharashtra Cricket Association are typically experienced individuals with a background in cricket coaching and playing. They are responsible for providing guidance, mentoring, and technical expertise to players to help them improve their skills and performance.</p>
                  

                </Row>
            </Container>

        </>
    )

}

export default Coaches;