
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ManageTendersNews() {
    return (
        <>

            <Container fluid className=''>
                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Manage Tenders News</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Manage Tenders News</a></div>
                    </Col>
                </Row>
                <Row className='m-3'>
                    <h3 className='text-center p-3'>Manage Tenders & News</h3>
                    <Col className='p-3'> 
                    <div className='text-center'>
                        <a href='./UploadTenders' target='_blank' className='btn btn-danger p-3'>Manage Tenders</a></div>
                    </Col>
                    <Col className='p-3'>
                    <div className='text-center'>

                        <a href='./UploadNews' target='_blank' className='btn btn-danger p-3'>Manage News</a>
                        </div>

                    </Col>

                </Row>
            </Container>

        </>
    )
}
export default ManageTendersNews;