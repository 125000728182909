import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Tlogo from '../../Assets/transferlogo.svg';
import McaQr from '../../Assets/mcaqr.png';
function Footer() {
  return (
    <>

      <footer className='bg-theme p-3'>




        <Container fluid className='text-dark'>
          <Row className='p-1 text-white'>
            <Col lg={6} className='p-2 d-flex align-items-center justify-content-center'>
              <div className=' p-1 text-center'><img src={Tlogo} className='text-white text-decoration-none ' /></div>
            </Col>
            {/* <Col lg={4} sm={6} className=''>
              <h5 className='p-1 fw-bold text-justify'>Links</h5>
              <div className=''>
              <h6 className=' py-1 '><Link to="/PrivacyPolicy" className='lightTxt text-decoration-none fw-bold'>Privacy Policy</Link></h6>
              <h6 className=' py-1 px-3'><Link to="/Terms" className=' text-decoration-none lightTxt fw-bold'>Terms & conditions </Link></h6>
              <h6 className=' py-1 '><Link to="/RefundPolicy" className=' text-decoration-none lightTxt fw-bold'>Refund Policy </Link></h6>
              <h6 className=' py-1 '><Link to="/Career" className='text-decoration-none lightTxt fw-bold'>Career</Link></h6></div>
            </Col> */}
            <Col lg={6} className='p-2 d-flex align-items-center justify-content-center'>
            <div className=''>

              <h5 className='p-1 fw-bold text-justify '>Contact Information</h5>
              <div>
                <span className='lightTxt fw-bolder text-justify'>Email :</span>  <span className='lightTxt'> cricketmaharashtra@yahoo.com</span>
                </div>
              <div>
                <span className='lightTxt fw-bolder text-justify'>Website :</span> <span className='lightTxt'> www.cricketmaharashtra.com  </span>
                </div>
                <div>
                <span className='lightTxt fw-bolder text-justify'>Contact :</span> <span className='lightTxt'> 020-27377162 </span>
                </div>
              <div>
                <span className='lightTxt fw-bolder text-justify'>Stadium :</span>
                 <span className='lightTxt'> Gahunje,Teh : Maval, Dist : Pune, Pin Code - 412 101 </span>
                 </div>
                <div>
                <span className='lightTxt fw-bolder text-justify'>Office:</span> 
                <span className='lightTxt'> MCA New office Address : Block-D, 1, Modibaug,<br/>
                   Narveer Tanaji Wadi, Shivajinagar, Pune - 411005. </span>
                </div>                </div>

            </Col>
            {/* <Col lg={4}>
             <div className='text-center'>
             <h5 className='p-1 fw-bold text-justify '>For Any Donation
             </h5>
              <img src={McaQr} alt='img' className='text-center img-fluid p-1'/>
             </div>
            </Col> */}
            
            </Row>
          <Row>
          <Col lg={12}>
              <h5 className='text-center p-1 fw-bold text-white'>Follow Us</h5>
              <div className='d-flex justify-content-between justify-content-sm-center my-4'>
                <a href="https://www.facebook.com/TheMaharashtraCricketAssociation" target="_blank"><i className="fa-brands fa-facebook fs-1 mx-2 text-white rotate"></i></a>
                <a href="https://www.instagram.com/maharashtracricketofficial/" target="_blank"><i className="fa-brands fa-instagram fs-1 mx-2 text-white rotate"></i></a>
                <a href="https://x.com/MahaCricket" target="_blank"><i className="fa-brands fa-twitter fs-1 mx-2 text-white rotate"></i></a>
                <a href="https://www.youtube.com/@MaharashtraCricketAssociation" target="_blank"><i className="fa-brands fa-youtube fs-1 mx-2 text-white rotate"></i></a>
              </div>
            </Col>
            <Col className='text-center p-1 text-white' lg={12}>
           

              <span >@{new Date().getFullYear()} MCA International Stadium. All Rights Reserved</span>
            </Col>
          </Row>
          </Container>
           </footer>
            </>
  )
}
export default Footer;