
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IncomePdf from '../../Assets/MCA/income.pdf';

function AnnualReports() {
    return (
        <>

            <Container fluid className=''>

                <Row className='headingimg1'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Annual Reports</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Annual Reports</a></div>
                    </Col>

                </Row>
                <Row className='p-3'>
               
               <Col>
                   <h4 className='p-3'>2023</h4>
                   <Row className='mx-3'>
<Col lg={3} className='border'>
   <h5 className='text-primary'>Annual Report</h5>
   <p>Maharashtra Cricket Association</p>

</Col>
<Col lg={3} className='border'>
<h5 className='text-primary'>As On</h5>
<p>31/03/2023</p>

</Col>


<Col lg={3} className='border'>
<h5 className='text-primary'>Download Pdf</h5>
   <a href={IncomePdf} target='_blank'>Click here</a>
</Col>
</Row>
               </Col>
           </Row>
                <Row className='m-3'>
                <h3 className='text-center p-3'>Annual Reports</h3>
                <p>The annual reports of the Maharashtra Cricket Association (MCA) typically provide comprehensive information about the activities, financial performance, and governance of the association over the course of a fiscal year. These reports are essential for members, stakeholders, and the public to understand the functioning and achievements of the association.</p>
                <p>Key components commonly found in annual reports of cricket associations like MCA include:</p>
                <p>Chairperson's or President's Message: An introductory message from the chairperson or president highlighting the key achievements, challenges, and priorities of the association during the year.</p>
                <p>Management Report: A detailed overview of the activities, events, and initiatives undertaken by the association in various areas such as cricket development, infrastructure, tournaments, and administration.</p>
                <p>Financial Statements: Comprehensive financial statements including income statements, balance sheets, and cash flow statements providing insights into the financial performance and position of the association.</p>
                <p>Governance Structure: Information about the governance structure, composition of the executive committee, roles and responsibilities of office bearers, and details of any changes or updates to the constitution or bylaws.</p>
                <p>Membership: Statistics on membership growth, categories of membership, and any changes to membership policies or procedures.</p>
                <p>Cricket Development: Details of programs, initiatives, and investments aimed at promoting and developing cricket at various levels, including grassroots, youth, and elite levels.</p>
                <p>Infrastructure Development: Updates on infrastructure projects, maintenance activities, and investments in facilities such as stadiums, grounds, and training centers.</p>
                <p>Sponsorship and Partnerships: Information about sponsorship agreements, partnerships, and revenue generated through commercial activities.</p>
                <p>Corporate Social Responsibility (CSR): Reports on CSR activities and initiatives undertaken by the association for the benefit of the community.</p>
                <p>Auditors' Report: Independent auditors' report providing an opinion on the fairness and accuracy of the financial statements.</p>
                <p>These components collectively provide a comprehensive overview of the MCA's performance and activities during the fiscal year. To access the specific annual reports of the Maharashtra Cricket Association, you can check their official website or contact the association directly for the latest documents.</p>

                </Row>
                 
            </Container>

        </>
    )

}

export default AnnualReports;