import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Slider from '../../Components/Slider';
import CardSlider from '../../Components/CardSlider';
import NavDropdown from 'react-bootstrap/NavDropdown';
import SmallIcon from '../../Assets/small1.png';
import News from '../../Components/News';
import Sketch from '../../Assets/skt.PNG';
import VideoSlider from '../../Components/VideoSlider';
// import Banner from '../../Components/Banner';
// import Marquee from '../../Components/Marquee';
import Sponser1 from '../../Assets/sponser1.png';
import Sponser2 from '../../Assets/sponser2.png';

function Home() {

    return (
        <>
            {/* <Marquee /> */}

            {/* <Banner /> */}

            <Slider />
      
            <Container className=''>

                <Row className='d-flex align-items-center justify-content-center m-3'>
                    <Col className='p-3 text-center' >
                        <img src={SmallIcon} alt='img1' />
                        <h1 className='text-theme'>ABOUT MCA</h1>
                        <p className='px-3 mx-2 text-justify'>Maharashtra Cricket Association is a dedicated platform that embodies the spirit of cricket in the Maharashtra region. As enthusiasts and advocates of the sport, our mission is to foster a vibrant cricketing community, nurturing talent from grassroots to professional levels. Through our comprehensive coverage, we strive to connect cricket enthusiasts, players, and stakeholders across Maharashtra. Our commitment extends beyond the boundary, aiming to instill a love for the game and contribute to the growth of cricket in the region. Join us in celebrating the rich cricketing heritage of Maharashtra and be part of a community that shares the passion for this beloved sport.</p>
                        <a href='./About' target='_blank' className=' btn btn-danger shadow '>Read More</a>
                    </Col>
                </Row>

            </Container>

            <News />

            <Container fluid className='px-3'>
                <Row className='d-flex align-items-center justify-content-center m-3'>
                    <h1 className='text-theme p-3 text-center'>MATCHES PLAYED AT MCA STADIUM</h1>
                    <Col className=' text-center' lg={2}>
                        <p className='fs-1 text-danger fw-bold count'></p>
                        <p className=' fw-bold'>TEST MATCHES</p>
                    </Col>
                    <Col className=' text-center' lg={2}>
                        <p className='fs-1 text-danger fw-bold count1'></p>
                        <p className=' fw-bold'>ONE DAY INTERNATIONAL</p>
                    </Col>
                    <Col className=' text-center' lg={2}>
                    <p className='fs-1 text-danger fw-bold count2'></p>
                        <p className=' fw-bold'>T/20 INTERNATIONAL </p>
                    </Col>
                    <Col className=' text-center' lg={2}>
                        <p className='fs-1 text-danger fw-bold count3'></p>
                        <p className=' fw-bold'>INDIAN PREMIER LEAGUE (IPL)</p>
                    </Col>
                    <Col className=' text-center' lg={2}>
                        <p className='fs-1 text-danger fw-bold count4'></p>
                        <p className='fw-bold'>TOTAL MATCHES</p>
                    </Col>
                </Row>
                </Container>
                <Container fluid>
                <Row className=''>
                    <img src={Sketch} alt='img2' className='img-fluid'/>
                </Row>
            </Container>
            <VideoSlider />
            <Container fluid>

            <Row className=''>
                <h2 className='text-center'>Sponsors</h2>
                <Col className='p-3 text-center' lg={6}>
                    <img src={Sponser1} alt='img2' className='img-fluid p-3'/>
                </Col>
                <Col className='p-3 text-center' lg={6}>
                    <img src={Sponser2} alt='img2' className='img-fluid p-3'/>
                </Col>
            </Row>            </Container>

        </>
    )

}

export default Home;